<template>
  <main class="main--not-logged">
    <div class="banner">
      <img
        :src="require('../assets/img/architecture-g19c3e4465_1920_low.jpg')"
        class="banner__img"
      />
    </div>
    <div class="main-content">
      <shout-box
        :data="shoutBoxData"
      >
        <template slot="custom-content">
          <div class="shoutbox__custom-content">
            <div v-if="tokenValid && !isCheck">
              <form-register
                :token="tokenId"
                :inviteEmail="userTokenEmail"
              />
            </div>
            <b-card class="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 p-0" v-else>
              <div v-if="isCheck">
                Controllo del token in corso
              </div>
              <div v-if="!isCheck" class="text-center py-5">
<!--                <div v-if="tokenValid">-->
<!--                  <form-register-->
<!--                    :token="tokenId"-->
<!--                    :inviteEmail="userTokenEmail"-->
<!--                  />-->
<!--                </div>-->
                <div>
                  Il codice ricevuto non è più valido.<br /><br />Richiedi un nuovo token per l'iscrizione
                </div>
                <div class="d-flex justify-content-center mt-4">
                  <router-link to="/accedi" class="btn btn-secondary btn-lg">
                    Accedi
                  </router-link>
                </div>
              </div>
            </b-card>
          </div>
        </template>
      </shout-box>
    </div>
    <footer-component />
  </main>
</template>

<script>
import { extractErrorMessage, isPresent } from '@/utils/validators';
import FormRegister from '@/components/forms/FormRegister.vue';

const ShoutBox = () => import('@/components/ShoutBox.vue');
const FooterComponent = () => import('@/components/layout/FooterComponent.vue');

export default {
  name: 'ValidateToken',
  components: { FormRegister, ShoutBox, FooterComponent },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    console.log('token', this.$route.params.token);
    // forzare un logout
    this.$store.dispatch('auth/resetToken');
    this.checkToken();
  },
  data() {
    return {
      userTokenEmail: null,
      shoutBoxInvalidData: {
        title: 'Accedi o <span class="text-warning">registrati</span> per utilizzare tutti i servizi di MF Solvency',
        subtitle: '',
      },
      shoutBoxValidData: {
        title: 'Imposta la password',
        subtitle: 'per accedere alla piattaforma',
      },
    };
  },
  computed: {
    tokenId() {
      return this.$route.params.token;
    },
    isCheck() {
      return this.$store.getters['subject/isLoading'];
    },
    tokenValid() {
      return isPresent(this.userTokenEmail);
    },
    shoutBoxData() {
      if (this.tokenValid) {
        return this.shoutBoxValidData;
      }

      return this.shoutBoxInvalidData;
    },
  },
  metaInfo() {
    return {
      title: 'Completa registrazione - MF Solvency',
      meta: [
        { property: 'og:title', content: 'Completa registrazione - MF Solvency' },
        { property: 'og:site_name', content: 'MF Solvency' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'noindex, nofollow' },
      ],
    };
  },
  methods: {
    checkToken() {
      this.$store.dispatch('subject/getTokenAccount', this.$route.params.token)
        .then(
          (data) => {
            console.log('result', data);
            this.userTokenEmail = data.data?.email || null;
          },
          (error) => {
            this.message = extractErrorMessage(error);
            this.userTokenEmail = null;
          },
        );
    },
  },
  mounted() {
    console.debug('ValidateToken setpreloader', false);
    this.$store.dispatch('setPreloader', false);
  },
};
</script>

<style scoped>

</style>
