import { render, staticRenderFns } from "./UploadRequest.vue?vue&type=template&id=234c1833&scoped=true&"
import script from "./UploadRequest.vue?vue&type=script&lang=js&"
export * from "./UploadRequest.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "234c1833",
  null
  
)

export default component.exports