<template>
  <main class="main--not-logged">
    <div class="banner">
      <img
        :src="require('../assets/img/architecture-g19c3e4465_1920_low.jpg')"
        class="banner__img"
      />
    </div>
    <div class="main-content">
      <shout-box
        :data="shoutBoxData"
      >
        <template v-slot:buttons>
          <div class="shoutbox__buttons">
            <div>
              <router-link class="btn btn-lg btn-success" to="/">HOME PAGE</router-link>
            </div>
          </div>
        </template>
      </shout-box>
      <div class="mt-5 pt-5">
        <b-container>
          <b-row>
            <b-col lg="8" offset-lg="2">
              <b-card class="bg-gray-700 border-0" body-class="p-md-5">
                <div class="d-flex flex-column flex-lg-row">
                  <div class="me-lg-auto">
                    <b-row>
                      <b-col lg="10">
                        <h3 class="text-white mb-0 text-center text-lg-start">
                          Contattaci per richiedere ulteriori informazioni sui nostri servizi e prodotti
                        </h3>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="mt-3 mt-lg-0 mx-auto me-lg-0 ms-lg-3 d-flex align-items-center">
                    <a
                      class="btn btn-secondary btn-lg"
                      href="https://www.crpagamenti.it/contatti/"
                      target="_blank"
                    >CONTATTACI</a>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <footer-component />
  </main>
</template>

<script>

const ShoutBox = () => import('@/components/ShoutBox.vue');
const FooterComponent = () => import('@/components/layout/FooterComponent.vue');

export default {
  name: 'Home',
  components: { ShoutBox, FooterComponent },
  data() {
    return {
      shoutBoxData: {
        title: 'Pagina <span class="text-warning">non</span> disponibile',
      },
    };
  },
  metaInfo() {
    return {
      title: 'Pagina non disponibile - MF Solvency',
      meta: [
        { property: 'og:title', content: 'MF Solvency' },
        { property: 'og:site_name', content: 'MF Solvency' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'noindex,nofollow' },
      ],
    };
  },
  mounted() {
    this.$store.dispatch('setPreloader', false);
  },
};
</script>
