import gql from 'graphql-tag';

const query = gql`query companyQuery ($taxCode: String!){
     company(taxCode: $taxCode) {
       info {
         companyName
         vatNumber
         taxCode
         legalCompanyType
       }
       attribute {
         companyCreationDate
         registrationNumber
         cciaaRegistrationDate
         startActivityDate
         mainPecEmail{
           contactData
         }
         atecoCode
       }
       atecoInfo {
         description
       }
       additionalInfos {
         protestFound
       }
       legal {
         personInfo{
           name
           surname
           email
         }
       }
       legalAddress {
         streetName
         streetNumber
         city
         province
         zip
         countryCode
       }
       addressList {
        streetName
        streetNumber
        city
        province
        countryCode
       }
       clickReport
       clickReportPdf{
         status
         lastUpdate
         documentId
       }
       scoring{
         year
         lastUpdate
         scoringLabel
         scoring
         scoringEquivalent
         creditLimit
         capitalisationTotal{
           point
         }
         profitabilityTotal{
           point
         }
         liquidityTotal{
           point
         }
         sectorTotal{
           point
         }
         clickSection {
           balanceAssets
           balanceAssetsDelta
           balanceMol
           balanceMolDelta
           balanceProfitLoss
           balanceProfitLossDelta
           balancePurchases
           balancePurchasesDelta
           balanceRevenue
           balanceRevenueDelta
           balanceType
           lastBalanceDate
           hasEvents
         }
       }
       reportFull{
         status
         documentId
       }
     }
   }
   `;

export default query;
