import jwtDecode from 'jwt-decode';
// import axios from 'axios';
// import { extractUserToken, keyStorage } from '@/utils/auth-header';
import { toastFailure, toastSuccess, toastUnableToComplete } from '@/utils/toast';
import AuthService from '../services/auth.service';
import TokenService from '@/services/token.service';

const pathDashboard = '/dashboard-utente';

const userData = TokenService.getUser();
const initialState = userData
  ? {
    status: { loggedIn: true },
    user: userData,
    userInfo: jwtDecode(userData.access_token),
    autoRefresh: null,
    pathLogin: pathDashboard,
  }
  : {
    status: { loggedIn: false },
    user: null,
    userInfo: null,
    autoRefresh: null,
    pathLogin: pathDashboard,
  };

const auth = {
  namespaced: true,
  state: initialState,
  getters: {
    currentUser: (state) => state.user || {},
    currentUserInfo: (state) => state.userInfo || {},
    currentUsername: (state) => (state.userInfo || {}).preferred_username,
    currentName: (state) => (state.userInfo || {}).name,
    isLogged: (state) => state.status.loggedIn,
    currentEmail: (state) => (state.userInfo || {}).email,
    getPath: (state) => state.pathLogin || pathDashboard,
  },
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (userResponse) => {
          commit('loginSuccess', userResponse);

          const self = this;
          const autoRefresh = setTimeout(() => {
            self.dispatch('auth/refreshToken');
          }, (userResponse.expires_in - 30) * 1000);

          commit('setTokenRefresh', autoRefresh);

          return Promise.resolve(userResponse);
        },
        (error) => {
          commit('loginFailure');
          toastFailure('Autenticazione fallita');
          return Promise.reject(error);
        },
      );
    },
    refreshToken({ commit }) {
      return AuthService.refreshToken().then(
        (userResponse) => {
          commit('loginSuccess', userResponse);
          const self = this;
          const autoRefresh = setTimeout(() => {
            self.dispatch('auth/refreshToken');
          }, (userResponse.expires_in - 30) * 1000);

          commit('setTokenRefresh', autoRefresh);

          return Promise.resolve(userResponse);
        },
        (error) => {
          commit('loginFailure');
          // toastFailure('Autenticazione fallita');
          return Promise.reject(error);
        },
      );
    },
    // logout({ commit }) {
    //   commit('logout');
    // },
    logout({ commit }) {
      return AuthService.logout().then(
        (userResponse) => {
          commit('logout');
          this.dispatch('subject/logout');
          toastSuccess('Logout effettuato');
          return Promise.resolve(userResponse);
        },
        (error) => {
          commit('logout');
          this.dispatch('subject/logout');
          toastSuccess('Logout effettuato');
          return Promise.resolve(error);
        },
      );
    },
    setPath({ commit }, path) {
      commit('setLoggedPath', path);
    },
    cleanPath({ commit }) {
      commit('setLoggedPath', pathDashboard);
    },
    cleanSession({ commit }) {
      commit('logout');
      // dispatch('subject/logout');
      return Promise.resolve();
    },
    forgotPassword({ commit }, {
      email,
      noLogout,
    }) {
      return AuthService.forgotPassword(email).then(
        (userResponse) => {
          console.log('noLogout', noLogout, email, userResponse);
          if (!noLogout) commit('logout');
          toastSuccess('Richiesta inviata');
          return Promise.resolve(userResponse);
        },
        (error) => {
          if (!noLogout) commit('logout error', error);
          return Promise.resolve(error);
        },
      );
    },
    resetToken({ commit }) {
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (userResponse) => {
          commit('registerSuccess', userResponse);
          return Promise.resolve(userResponse);
        },
        (error) => {
          commit('registerFailure');
          // console.log('register error', error);
          if (error.status === 409) {
            const errorMsg = { field: 'email', message: 'Indirizzo email già presente' };
            return Promise.reject(errorMsg);
          }
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    accountRegister({ commit }, user) {
      return AuthService.accountRegister(user).then(
        (userResponse) => {
          console.log('success', userResponse);
          return Promise.resolve(userResponse);
        },
        (error) => {
          commit('registerFailure');
          // console.log('register error', error);
          if (error.status === 409) {
            const errorMsg = { field: 'email', message: 'Indirizzo email già presente' };
            return Promise.reject(errorMsg);
          }
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
      state.userInfo = jwtDecode(user.access_token);
    },
    setTokenRefresh(state, refresh) {
      state.autoRefresh = refresh;
    },
    setLoggedPath(state, path) {
      state.pathLogin = path;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
      state.userInfo = null;
      if (state.autoRefresh !== null) {
        clearTimeout(state.autoRefresh);
        state.autoRefresh = null;
      }
      TokenService.removeUser();
      // localStorage.removeItem(keyStorage);
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
      state.userInfo = null;
      if (state.autoRefresh !== null) {
        clearTimeout(state.autoRefresh);
        state.autoRefresh = null;
      }
      TokenService.removeUser();
      // localStorage.removeItem(keyStorage);
      // delete axios.defaults.headers.common.Authorization;
    },
    // eslint-disable-next-line no-unused-vars
    logoutFailure(state) {
      console.log('logoutFailure');
    },
    registerSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
      state.userInfo = jwtDecode(user.access_token);
    },
    registerFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
      state.userInfo = null;
      if (state.autoRefresh !== null) {
        clearTimeout(state.autoRefresh);
        state.autoRefresh = null;
      }
    },
  },
};

export default auth;
