// noinspection JSUnusedLocalSymbols

import moment from 'moment';
import { isNotEmpty, isNotExist, isPresent } from './validators';

const numeral = require('numeral/numeral');

// eslint-disable-next-line no-unused-vars
const locales = require('numeral/locales');

export const DATE_TIME = 'DATE_TIME';
export const TIME = 'TIME';
export const DATE = 'DATE';
export const CURRENCY = 'CURRENCY';
export const DECIMAL = 'DECIMAL';
export const INTEGER = 'INTEGER';
export const PERCENT = 'PERCENT';
export const TEXT = 'TEXT';

// const locales = {
//   it: 'it-IT',
//   en: 'en-US',
// };

const dateFormat = {
  it: 'dd/MM/yyyy',
  en: 'MM/dd/yyyy',
};

const currencies = {
  it: 'EUR',
  en: 'USD',
};

// const getLocale = (language) => locales[language] || locales.it;
const getLocale = (language) => language || 'it';

export const getLocaleComplete = (language) => getLocale(language);
export const getDateFormat = (language) => dateFormat[language] || dateFormat.it;
const getCurrency = (language) => currencies[language] || currencies.it;
//
// // load a locale
// numeral.register('locale', 'it', {
//   delimiters: {
//     thousands: '.',
//     decimal: ','
//   },
//   abbreviations: {
//     thousand: 'k',
//     million: 'm',
//     billion: 'b',
//     trillion: 't'
//   },
//   currency: {
//     symbol: '€'
//   }
// });

export const convertDateToMoment = (date) => {
  if (isNotEmpty(date)) {
    return moment(date);
  }
  return null;
};

export const convertDate = (date) => {
  if (isNotEmpty(date)) {
    return convertDateToMoment(date).toDate();
  }
  return null;
};

export const formatDateTime = (date, language) => {
  if (isNotExist(date)) return '';
  const locale = getLocale(language);
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  }).format(convertDate(date));
};

export const formatDateOnly = (date, language) => {
  if (isNotExist(date)) return '';
  const locale = getLocale(language);
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(convertDate(date));
};

export const formatYearOnly = (date, language) => {
  if (isNotExist(date)) return '';
  const locale = getLocale(language);
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
  }).format(convertDate(date));
};

export const formatTimeOnly = (date, language) => {
  if (isNotExist(date)) return '';
  const locale = getLocale(language);
  return new Intl.DateTimeFormat(locale, {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  }).format(convertDate(date));
};

const processFormat = (value, locale, settings) => {
  numeral.locale(locale);
  return numeral(value).format(settings);
};

export const formatCurrency = (value, language, currencyCode) => {
  if (isNotExist(value)) return '';
  let currency = currencyCode;
  const locale = getLocale(language);
  if (!isPresent(currencyCode)) {
    currency = getCurrency(language);
  }
  // return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(Number(value));
  // return formatDecimal(value, language);
  //  return processFormat(value, locale, "0,0.00 $");
  return `${processFormat(value, locale, '0,0.00')} ${currency}`;
};

export const formatPercent = (value, language) => {
  if (isNotExist(value)) return '';
  const locale = getLocale(language);
  // return new Intl.NumberFormat(locale, {
  //   style: 'percent',
  //   minimumFractionDigits: 2,
  //   maximumFractionDigits: 2,
  // }).format(Number(value));
  return processFormat(value, locale, '0.00%');
};

export const formatDecimalNumber = (value, digits, language) => {
  if (isNotExist(value)) return '';
  const locale = getLocale(language);

  let format = '0,0';
  if (digits > 0) {
    format += '.';
    for (let i = 0; i < digits; i += 1) {
      format += '0';
    }
  }
  return processFormat(value, locale, format);
  // return new Intl.NumberFormat(locale, {
  //   minimumFractionDigits: digits,
  //   maximumFractionDigits: digits,
  // }).format(Number(value));
};

export const formatDecimal = (value, language) => formatDecimalNumber(value, 2, language);

export const formatInteger = (value, language) => formatDecimalNumber(value, 0, language);

export const formatNumberHuman = (value, language) => {
  if (isNotExist(value)) return '';
  const locale = getLocale(language);

  return processFormat(value, locale, '0a');
};

export const formatCurrencyHuman = (value, language, currencyCode) => {
  if (isNotExist(value)) return '';
  let currency = currencyCode;
  const locale = getLocale(language);
  if (!isPresent(currencyCode)) {
    currency = getCurrency(language);
  }
  // return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(Number(value));
  // return formatDecimal(value, language);
  //  return processFormat(value, locale, "0,0.00 $");
  return `${processFormat(value, locale, '0a')} ${currency}`;
};

export const formatField = (value, type, language) => {
  if (!isPresent(type)) return value;

  if (type === DATE_TIME) {
    return formatDateTime(value, language);
  }
  if (type === DATE) {
    return formatDateOnly(value, language);
  }
  if (type === TIME) {
    return formatTimeOnly(value, language);
  }
  if (type === CURRENCY) {
    return formatCurrency(value, language);
  }
  if (type === DECIMAL) {
    return formatDecimal(value, language);
  }
  if (type === INTEGER) {
    return formatInteger(value, language);
  }
  if (type === PERCENT) {
    return formatPercent(value, language);
  }

  return value;
};

const formats = {};

export const numericSeparator = (language) => {
  const locale = getLocale(language);
  const format = formats[locale] || {};
  const formatter = Intl.NumberFormat(locale); // .format(Number(value));

  if (format.decimal === undefined) {
    format.thousand = formatter.format(1111).replace(/1/g, '');
    format.decimal = formatter.format(1.1).replace(/1/g, '');
    formats[locale] = format;
  }
  return format;
};

export const extractDeepLevel = (itemList, field, subField) => (!subField
  ? itemList.map((item) => item[field])
  : itemList.map((item) => item[field][subField]));

export const createThumbUrl = (imageId) => `/exre-public/${imageId}.thumb`;

export const createImageUrl = (imageId) => `/exre-public/${imageId}`;

export const createAttachUrl = (attachId) => `/exre-public/${attachId}`;

export const editorConfig = {
  plugins: 'link,image,lists,paste,code',
  toolbar:
    'undo redo | formatselect bullist numlist | bold italic link | code paste',
  block_formats: 'Paragraph=p;Heading 3=h3',
  menubar: true,
  statusbar: false,
  body_class: 'editable-field-content',
  // paste_word_valid_elements: 'b,strong,i,em,h1,h2,h3,p,li,ul,ol,a',
  // paste_retain_style_properties: 'none',
  paste_strip_class_attributes: 'none',
  paste_remove_styles: true,
};

// get name and capitalize also after apostrophe
export const normalizeDisplayName = (name) => (name ? name.replace(/\b(\w)/g, (s) => s.toUpperCase()) : '');
