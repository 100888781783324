<template>
  <b-card class="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 p-0">
    <div class="form-inputs--lg">
      <b-form @submit.prevent="submitPrivato" class="mb-3">
        <b-form-row>
          <b-col md="6">
            <field-text
              fieldId="firstName"
              placeholder="Nome"
              :state="validateState($v.formPrivato.firstName)"
              v-model="$v.formPrivato.firstName.$model"
            />
          </b-col>
          <b-col md="6">
            <field-text
              fieldId="lastName"
              placeholder="Cognome"
              :state="validateState($v.formPrivato.lastName)"
              v-model="$v.formPrivato.lastName.$model"
            />
          </b-col>
        </b-form-row>
        <field-email
          :read-only="isInvited"
          fieldId="email"
          placeholder="Email"
          :state="validateState($v.formPrivato.email)"
          v-model="$v.formPrivato.email.$model"
          :errorMessage="decodeError($v.formPrivato, 'email', this.formPrivato)"
        />
        <field-password
          fieldId="password"
          placeholder="Scegli una password"
          :state="validateState($v.formPrivato.password)"
          v-model="$v.formPrivato.password.$model"
        />
        <field-password
          fieldId="confirmPassword"
          placeholder="Conferma la tua password"
          :state="validateState($v.formPrivato.confirmPassword)"
          v-model="$v.formPrivato.confirmPassword.$model"
          :errorMessage="decodeError($v.formPrivato, 'confirmPassword', this.formPrivato)"
        />
        <hr class="my-4" />
        <div class="text-center pt-2">
          <button-loader
            content="Invia"
            :disabled="$v.formPrivato.$invalid"
            buttonStyle="success"
            buttonClass="btn-block btn-lg"
          />
        </div>
      </b-form>
      <p class="m-0 text-center">Sei già registrato?
        <b-link to="accedi">Accedi</b-link>
      </p>
      <p class="m-0 text-center">Hai dimenticato la password?
        <b-link to="password-recovery">Clicca qui</b-link>
      </p>
    </div>
  </b-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import sameAs from 'vuelidate/lib/validators/sameAs';
import email from 'vuelidate/lib/validators/email';
import minLength from 'vuelidate/lib/validators/minLength';
import lodash from 'lodash';
import {
  decodeFormFieldError,
  extractErrorMessage,
  isPresent,
  removeProp,
  serverError,
  validateFormFieldState,
} from '@/utils/validators';

const ButtonLoader = () => import('@/components/ButtonLoader.vue');
const FieldText = () => import('@/components/fields/FieldText.vue');
const FieldEmail = () => import('@/components/fields/FieldEmail.vue');
const FieldPassword = () => import('@/components/fields/FieldPassword.vue');

export default {
  name: 'FormRegister',
  components: {
    FieldPassword, ButtonLoader, FieldText, FieldEmail,
  },
  props: {
    token: String,
    inviteEmail: String,
  },
  data() {
    return {
      submitted: false,
      formPrivato: {
        lastName: '',
        firstName: '',
        email: this.inviteEmail,
        password: '',
        confirmPassword: '',
      },
      clientValidation: {
        formPrivato: {
          firstName: {
            required,
          },
          lastName: {
            required,
          },
          email: {
            required,
            email,
          },
          password: {
            required,
            minLength: minLength(8),
          },
          confirmPassword: {
            sameAsPassword: sameAs(function () {
              return this.formPrivato.password;
            }),
          },
        },
      },
      serverValidation: {},
    };
  },
  computed: {
    rules() {
      // When the serverValidation property is set this computed property
      // fires and merges the client and server validation
      return lodash.merge({}, this.serverValidation, this.clientValidation);
    },
    isCheck() {
      return this.$store.getters['subject/isCheckTaxCode'];
    },
    isInvited() {
      return isPresent(this.token);
    },
  },
  mounted() {
    this.formPrivato.email = this.inviteEmail;
    this.formPrivato.confirmEmail = this.inviteEmail;
  },
  validations() {
    return this.rules;
  },
  methods: {
    validateState: validateFormFieldState,
    decodeError: decodeFormFieldError,
    clearServerErrors() {
      // Clearing the server validation removes the rules and
      // therefore server errors no longer contribute to validation state.
      this.serverValidation = {};
      // Clearing the server errors from the data isn't necessary either
      // but again makes for good housekeeping and we'd need to do this
      // if submitting this data to the server.
      removeProp(this.formData, 'serverErrors');
    },
    clearServerError(model, fieldName) {
      // When an input control is modified we see if there are any
      // server errors and clear them. This causes the serverError validation
      // rule to trigger and clear it's error state.
      // eslint-disable-next-line no-prototype-builtins
      if (model.hasOwnProperty('serverErrors')) {
        // eslint-disable-next-line no-prototype-builtins
        if (model.serverErrors.hasOwnProperty(fieldName)) {
          // eslint-disable-next-line no-param-reassign
          delete model.serverErrors[fieldName];
        }
      }
    },
    clearTaxCodeForm(e) {
      console.log('clearTaxCodeForm', e);
      this.$v.formPrivato.personTaxCode.$reset();
      if (this.formPrivato.serverErrors?.personTaxCode) delete this.formPrivato.serverErrors.personTaxCode;
      if (this.serverValidation.formPrivato?.personTaxCode) delete this.serverValidation.formPrivato.personTaxCode;
    },
    submitPrivato() {
      const { token } = this;
      const emailUser = this.isInvited ? this.inviteEmail : this.formPrivato.email;
      const user = {
        email: emailUser,
        firstName: this.formPrivato.firstName,
        lastName: this.formPrivato.lastName,
        password: this.formPrivato.password,
        confirmPassword: this.formPrivato.confirmPassword,
        token,
      };
      console.log('user', user);
      this.clearServerErrors();
      if (this.$v.formPrivato.$invalid) return;
      this.submitted = true;
      this.$store.dispatch('auth/register', user).then(
        (response) => {
          this.message = response.message;
          this.successful = true;
          console.log('Registred');
          this.$store.dispatch('subject/registerAccountPerson', user).then(
            () => {
              console.log('Registred completed');
              this.submitted = false;
              this.$v.formPrivato.$reset();
              this.$router.replace('/dashboard-utente');
            },
            (error) => {
              this.message = extractErrorMessage(error);
              this.successful = false;
              this.submitted = false;
            },
          );
        },
        (error) => {
          this.message = extractErrorMessage(error);
          const { field } = error;
          if (isPresent(field)) {
            const serverMessages = {
              serverErrors: {},
            };

            serverMessages.serverErrors[field] = this.message;

            // Merge the server errors into the data. This doesn't yet cause
            // any validation errors but does make the messages (and therefore state)
            // accessible to the template.
            lodash.merge(this.formPrivato, serverMessages);
            // From the serverMessage we should create a validation object which will
            // be merged with the client validation objects. It's at this point the
            // computed property changes and Vuelidate updates it's validation
            this.serverValidation = {
              formPrivato: {},
            };
            this.serverValidation.formPrivato[field] = {
              serverError: serverError(field, false),
            };
          }

          this.successful = false;
          this.submitted = false;
        },
      );
    },
    checkTaxCode() {
      this.$v.formPrivato.personTaxCode.$touch();
      // console.log('checkTaxCode', e, this.$v.formPrivato.personTaxCode, this.validateState(this.$v.formPrivato.personTaxCode));
      // if (!this.validateState(this.$v.formPrivato.personTaxCode)) {
      //   return;
      // }
      const serverMessages = {
        serverErrors: {
          personTaxCode: null,
        },
      };
      this.$store.dispatch('subject/checkTaxCode', this.formPrivato.personTaxCode).then(() => {
        // console.log('result', result);
        // lodash.merge(this.formPrivato, serverMessages);
        // this.$v.formPrivato.personTaxCode.$reset();
        // this.$v.formPrivato.personTaxCode.$touch();
        if (this.formPrivato.serverErrors?.personTaxCode) delete this.formPrivato.serverErrors.personTaxCode;
        if (this.serverValidation.formPrivato?.personTaxCode) delete this.serverValidation.formPrivato.personTaxCode;
      }).catch(() => {
        // console.log('error', error);
        const field = 'personTaxCode';
        serverMessages.serverErrors[field] = 'Codice fiscale già presente';
        lodash.merge(this.formPrivato, serverMessages);
        this.serverValidation = {
          formPrivato: {},
        };
        this.serverValidation.formPrivato[field] = {
          serverError: serverError(field, false),
        };
      });
    },
  },
};
</script>
