<template>
  <div class="mt-3">
    <user-structure is-dashboard>
      <template #banner>
        <div class="banner">
          <img
            :src="require('../assets/img/bg-crp_low.jpg')"
            class="banner__img"
          />
          <div class="banner__content__wrapper" v-if="extendedStatus.userStatus !== 'wait'">
<!--            <div class="banner__content banner__content&#45;&#45;badge">-->
<!--              <div class="banner__content__img">-->
<!--                <b-img fluid :src="profileImgUrl" />-->
<!--              </div>-->
<!--              <div class="banner__content__data">-->
<!--                <div class="banner__content__data__top mb-0">-->
<!--                  <p>-->
<!--                    {{currentName}}-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <div
              class="banner__content banner__content--wallet"
              v-if="isOperative"
            >
              <div class="banner__content__img">
                <font-awesome-icon
                  icon="wallet"
                  class="fa-fw"
                />
              </div>
              <div class="banner__content__data">
                <div class="banner__content__data__top">
                  <p>Credito disponibile</p>
                </div>
                <div class="banner__content__data__bottom">
                  <div class="d-flex align-items-center">
                    <h5 class="mb-0">
                      {{walletValue}}
                    </h5>
                  </div>
                  <div>
                    <b-button
                      variant="info"
                      size="sm"
                      @click="routeTo('/wallet')"
                    >
                      Wallet
                    </b-button>
                  </div>
                </div>
                <div class="banner__content__data__bottom__cockade" v-if="prePaid">
                  <div class="pt-2" v-for="item in prepaidList" :key="item.code">
                    <p class="text-prepaid">{{item.name}} disponibili: {{item.amount}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #body>
        <div>
          <b-row v-if="isActiveUser && isOperative">
            <b-col
              :md="topRowMdColWidth"
              :xl="topRowLgColWidth"
              class="dashcard__wrapper mt-3"
              order="1"
              order-md="0"
            >
              <div class="dashcard">
                <div class="dashcard__header">
                  <div class="dashcard__icon">
                    <inline-svg
                      :src="require('../assets/img/market-analysis.svg')"
                      fill="#0a2538"
                      color="#0a2538"
                      height="30px"
                      viewBox="0 0 100 100"
                      width="30px"
                    ></inline-svg>
                  </div>
                  <span class="dashcard__title">
                    Ricerca Azienda
                  </span>
                </div>
                <b-row>
                  <b-col  md="12" lg="12">
                    <div class="dashcard__body">
                      <p>
                        Inserisci la partita iva o la ragione sociale dell'azienda che
                        vuoi analizzare:
                      </p>
                    </div>
                    <div class="dashcard__buttons">
                      <div>
                        <b-col>
                          <p class="text-left">Ricerca per</p>
                        </b-col>
                        <b-col>
                            <div class="custom-input-group has--append mb-2">
                              <field-text
                                fieldId="companyName"
                                placeholder="Ragione Sociale"
                                v-model="currentSelectionName"
                                lg
                                :state="null"
                                class="flex-grow-1"
                              />
                              <div class="custom-input-group__append">
                                <b-button
                                  variant="info"
                                  @click="openInfo"
                                >
                                  <font-awesome-icon icon="info-circle"/>
                                </b-button>
                              </div>
                            </div>
                        </b-col>
                        <b-col>
                          <p class="text-center">oppure</p>
                        </b-col>
                        <b-col>
                          <div class="custom-input-group has--append">
                            <field-text
                              fieldId="taxCode"
                              placeholder="Partita Iva"
                              v-model="currentSelectionTaxCode"
                              lg
                              :state="null"
                              class="flex-grow-1"
                            />
                            <div class="custom-input-group__append">
                              <b-button
                                variant="info"
                                @click="openInfo"
                              >
                                <font-awesome-icon icon="info-circle"/>
                              </b-button>
                            </div>
                          </div>
                        </b-col>
                        <b-col>
                          <b-button
                            size="lg"
                            variant="info"
                            block
                            :disabled="!hasSearchData"
                            @click="openSearchModal"
                          >
                            ESEGUI LA RICERCA
                          </b-button>
                        </b-col>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col
              :md="topRowMdColWidth"
              :xl="topRowLgColWidth"
              class="dashcard__wrapper mt-0 mt-md-3"
              order="0"
              order-md="1"
            >
              <div class="dashcard">
                <div class="dashcard__header">
                  <div class="dashcard__icon">
                    <inline-svg
                      :src="require('../assets/img/icons/CLICKREPORT.svg')"
                      fill="#0a2538"
                      color="#0a2538"
                      height="30px"
                      width="30px"
                    ></inline-svg>
                  </div>
                  <span class="dashcard__title">
                    Click report
                  </span>
                </div>
                <b-row>
                  <b-col md="12" lg="12">
                    <div class="dashcard__body">
                      <p>
                        Inserisci la partita iva dell'azienda che
                        vuoi analizzare:
                      </p>
                    </div>
                    <div class="dashcard__buttons">
                      <div>
                        <b-col>
                          <div class="custom-input-group has--append">
                            <field-text
                              fieldId="taxCode"
                              placeholder="Partita Iva"
                              v-model="currentClickTaxCode"
                              lg
                              :state="null"
                              class="flex-grow-1"
                            />
                            <div class="custom-input-group__append">
                              <b-button
                                variant="info"
                                @click="openInfo"
                              >
                                <font-awesome-icon icon="info-circle"/>
                              </b-button>
                            </div>
                          </div>
                        </b-col>
                        <b-col>
                          <b-button
                            size="lg"
                            variant="info"
                            block
                            :disabled="!hasSearchClickData"
                            @click="openClickReport"
                          >
                            {{labelSearchClick}}
                          </b-button>
                        </b-col>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="dashcard__wrapper mt-3" v-if="isOperative">
              <div class="dashcard">
                <div class="dashcard__header">
                  <div class="dashcard__icon">
                    <inline-svg
                      :src="require('../assets/img/financial.svg')"
                      fill="#0a2538"
                      color="#6C757D"
                      height="30px"
                      viewBox="0 0 100 100"
                      width="30px"
                    ></inline-svg>
                  </div>
                  <span class="dashcard__title">
                    Ultime richieste
                  </span>
                </div>
                <div class="dashcard__body">
                  <user-analysis-history-table
                    :complete-data="hasScoreData"
                    :data-size="3"
                    minimal
                    :update-key="currentName.toString()"
                  />
                </div>
              </div>
            </b-col>
            <b-col lg="8" offset-lg="2" class="dashcard__wrapper" v-if="!isOperative && !isWaitApproval">
              <div class="dashcard">
                <div class="dashcard__header">
                  <div class="dashcard__icon">
                    <inline-svg
                      :src="require('../assets/img/financial.svg')"
                      fill="#0a2538"
                      color="#6C757D"
                      height="30px"
                      viewBox="0 0 100 100"
                      width="30px"
                    ></inline-svg>
                  </div>
                  <span class="dashcard__title">
                  SERVIZI MF SOLVENCY
                </span>
                </div>
                <div class="dashcard__body">
                  <p>
                    COMPLETA IL TUO PROFILO PER ACCEDERE A TUTTI I SERVIZI DISPONIBILI
                  </p>
                </div>
                <div class="dashcard__buttons">
                  <b-button
                    size="lg"
                    variant="warning"
                    block
                    @click="routeTo('/user-data')"
                  >
                    AGGIORNA PROFILO
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
          <profile-search-data-search-modal-info :id="infoModalId" />
          <profile-search-data-search-modal
            :id="searchModalId"
            :searchFunction="doQuickSearch"
            :searchData="{
              name: currentSelectionName,
              taxCode: currentSelectionTaxCode,
              }"
          />
          <click-report-search-modal
            :id="searchClickModalId"
            :searchFunction="doClickReport"
            :searchData="{
              taxCode: currentClickTaxCode,
              }"
          />
        </div>
      </template>
    </user-structure>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import { debounce } from 'lodash';
import { Settings } from 'luxon';
import {
  checkTaxCode, extractErrorMessage, isNotEmpty, isPresent, validateVat,
} from '@/utils/validators';
import { searchCompany } from '@/utils/selectSearch';
import { toastInformation } from '@/utils/toast';
import { createPublicUri } from '@/utils/utilities';
import { formatCurrency, formatDecimal, normalizeDisplayName } from '@/utils/formatterHelper';
import ClickReportSearchModal from '@/components/profileSearch/search/ClickReportSearchModal.vue';

const FieldText = () => import('@/components/fields/FieldText.vue');
const UserStructure = () => import('@/components/layout/UserStructure.vue');
const UserAnalysisHistoryTable = () => import('@/components/userAnalysis/UserAnalysisHistoryTable.vue');
const ProfileSearchDataSearchModalInfo = () => import('@/components/profileSearch/search/ProfileSearchDataSearchModalInfo.vue');
const ProfileSearchDataSearchModal = () => import('@/components/profileSearch/search/ProfileSearchDataSearchModal.vue');

export default {
  name: 'UserDashboard',
  components: {
    ClickReportSearchModal,
    UserStructure,
    FieldText,
    InlineSvg,
    UserAnalysisHistoryTable,
    ProfileSearchDataSearchModalInfo,
    ProfileSearchDataSearchModal,
  },
  data() {
    return {
      hasLoaded: null,
      hasSearchResults: false,
      userChosenType: 'person',
      currentSelection: '',
      entityType: 'PERSON',
      entityId: '',
      options: [],
      personName: '',
      companyName: '',
      dataTax: '',
      completed: false,
      hasError: false,
      hasWrongTaxCode: false,
      // eslint-disable-next-line global-require
      userPlaceholder: require('@/assets/img/user-img_placeholder.png'),
      // eslint-disable-next-line global-require
      companyPlaceholder: require('@/assets/img/company-img_placeholder.png'),
      currentSelectionName: '',
      currentSelectionTaxCode: '',
      currentClickTaxCode: '',
    };
  },
  computed: {
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    hasScoreData() {
      // if (this.hasLoaded !== this.$store.getters['score/hasCurrentScore']) {
      //   this.changePreloaderStatus(this.$store.getters['score/hasCurrentScore']);
      // }
      return this.$store.getters['score/hasCurrentScore'];
    },
    serialCode() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.serialCode;
      }
      return '';
    },
    currentName() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/currentCompany'].info.companyName;
      }
      return normalizeDisplayName(this.$store.getters['auth/currentName']);
    },
    userEmail() {
      return this.$store.getters['auth/currentEmail'];
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        // console.log('logging company', this.$store.getters['subject/activityCompanyStatus']);
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
    isWaitApproval() {
      return this.activityStatus.waitApproval;
    },
    totalPoint() {
      if (this.activityStatus.operative === false) return 'N.D.';
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return formatDecimal(score.totalPoint);
      }
      return 0;
    },
    countTotal() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.globalVotes?.countTotal;
      }
      return 0;
    },
    countMax() {
      let increm = this.countTotal * 0.2;
      if (increm < 1) {
        increm = 2;
      }
      return this.countTotal + increm;
    },
    extendedStatus() {
      return this.$store.getters['subject/currentExtendedStatus'];
    },
    isActiveUser() {
      return this.$store.getters['subject/isActiveUser'];
    },
    isCompany() {
      return this.userChosenType === 'company';
    },
    isPerson() {
      return this.userChosenType === 'person';
    },
    baseButtonClasses() {
      return 'btn-wizard--large';
    },
    placeholderImg() {
      if (this.isCompanyProfile) return this.companyPlaceholder;
      return this.userPlaceholder;
    },
    profileImgUrl() {
      const imageProfile = this.$store.getters['subject/getImage']('PROFILE');
      return isNotEmpty(imageProfile)
        ? createPublicUri(imageProfile.documentUpload.externalId)
        : this.placeholderImg;
    },
    walletValue() {
      if (isNotEmpty(this.$store.getters['wallet/getWallet']?.amount)) {
        const a = this.$store.getters['wallet/getWallet']?.amount.amount;
        const b = this.$store.getters['wallet/getWallet']?.amount.currency;
        return formatCurrency(a, 'it', b);
      }
      return 'N.D.';
    },
    topRowMdColWidth() {
      // return this.isCompanyProfile ? '4' : '6';
      return 6;
    },
    topRowLgColWidth() {
      // return this.isCompanyProfile ? '4' : '6';
      return 6;
    },
    searchModalId() {
      return 'searchModal';
    },
    searchClickModalId() {
      return 'searchClickModal';
    },
    infoModalId() {
      return 'infoModal';
    },
    hasSearchData() {
      return isPresent(this.currentSelectionName) || isPresent(this.currentSelectionTaxCode);
    },
    hasSearchClickData() {
      return isPresent(this.currentClickTaxCode);
    },
    prePaid() {
      const wallet = this.$store.getters['wallet/getWallet'];
      if (wallet) {
        /*
        {"pr_company_other":{"productType":"pr_company_other","reportPrePaid":0,"productsCodePrePaid":{"pr_company_search":9}}}
         */
        if (wallet.productsTypePrePaid) {
          const productTypes = Object.keys(wallet.productsTypePrePaid);
          let total = 0;
          productTypes.forEach((product) => {
            const { productsCodePrePaid } = wallet.productsTypePrePaid[product];
            if (productsCodePrePaid) {
              const productCodes = Object.keys(productsCodePrePaid);
              productCodes.forEach((code) => {
                total += productsCodePrePaid[code];
              });
            }
          });
          return total > 0;
        }
      }
      return false;
    },
    labelSearchClick() {
      return this.prePaid ? 'SCARICA CLICK REPORT' : 'ACQUISTA CLICK REPORT';
    },
    clickPrice() {
      return this.$store.getters['wallet/getPricing']('pr_company_other', 'pr_company_click_report')?.price;
    },
    searchPrice() {
      return this.$store.getters['wallet/getPricing']('pr_company_other', 'pr_company_search')?.price;
    },
    hasFund() {
      // console.log('check fund');

      const myWallet = this.$store.getters['wallet/getWallet'] || { currency: 'EUR', amount: 0 };
      // console.debug('my wallet', myWallet);
      return (myWallet.amount.amount - this.searchPrice.amount) >= 0;
    },
    hasSearchFund() {
      // console.log('check fund');

      const myWallet = this.$store.getters['wallet/getWallet'] || { currency: 'EUR', amount: 0 };
      // console.debug('my wallet', myWallet);
      return (myWallet.amount.amount - this.clickPrice.amount) >= 0;
    },
    prepaidList() {
      const wallet = this.$store.getters['wallet/getWallet'];
      if (!wallet) return [];
      const { productsTypePrePaid } = wallet;
      if (!productsTypePrePaid) return [];
      const prepaids = [];

      const productTypes = Object.keys(productsTypePrePaid);
      productTypes.forEach((product) => {
        const { productsCodePrePaid } = productsTypePrePaid[product];
        if (productsCodePrePaid) {
          const productCodes = Object.keys(productsCodePrePaid);
          const lista = this.$store.getters['tableUtils/getTableList'](product) || [];
          productCodes.forEach((code) => {
            const prod = lista.find((item) => item.key === code);
            if (prod) {
              if (productsCodePrePaid[code] > 0) {
                prepaids.push({
                  code: `${product}_${code}`,
                  name: prod.value,
                  amount: productsCodePrePaid[code],
                });
              }
            }
          });
        }
      });

      return prepaids;
    },
    hasPrepaid() {
      const myWallet = this.$store.getters['wallet/getWallet'] || { currency: 'EUR', amount: 0 };
      const { productsTypePrePaid } = myWallet;
      if (!productsTypePrePaid) return false;
      console.log('hasPrepaid', productsTypePrePaid);
      const productType = productsTypePrePaid.pr_company_other;
      if (productType) {
        console.log('hasPrepaid productType', productType);
        const clickReports = productType.productsCodePrePaid?.pr_company_click_report || 0;
        return clickReports > 0;
      }
      return false;
    },
    hasSearchPrepaid() {
      const myWallet = this.$store.getters['wallet/getWallet'] || { currency: 'EUR', amount: 0 };
      const { productsTypePrePaid } = myWallet;
      if (!productsTypePrePaid) return false;
      const productType = productsTypePrePaid.pr_company_other;
      if (productType) {
        const clickReports = productType.productsCodePrePaid?.pr_company_search || 0;
        return clickReports > 0;
      }
      return false;
    },
  },
  metaInfo() {
    return {
      title: 'Area utente - MF Solvency',
      meta: [
        { property: 'og:title', content: 'Area utente - MF Solvency' },
        { property: 'og:site_name', content: 'MF Solvency' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'noindex,nofollow' },
      ],
    };
  },
  // beforeCreate() {
  //   console.log('preloader dash beforeCreate', this.$store.getters.isPreloading);
  //   this.$store.dispatch('setPreloader', true);
  // },
  // created() {
  //   console.log('preloader dash created', this.$store.getters.isPreloading);
  //   this.$store.dispatch('setPreloader', true);
  // },
  beforeMount() {
    console.debug('UserDashboard beforemount setpreloader', true);
    this.$store.dispatch('setPreloader', true);
  },
  mounted() {
    this.fetchDecodes();
    // console.log('preloader dash cehck', this.$store.getters.isPreloading);
    // this.$nextTick(() => {
    //   // Code that will run only after the
    //   // entire view has been rendered
    //   console.log('preloader dash cehck tick', this.$store.getters.isPreloading);
    //   if (this.$store.getters.isPreloading) {
    //     console.log('preloader dash cehck tick if', this.$store.getters.isPreloading);
    //     this.$store.dispatch('setPreloader', false);
    //   }
    //   console.log('preloader dash cehck tick post', this.$store.getters.isPreloading);
    // });
    // this.$store.dispatch('setPreloader', true);
    // console.log('subject check', this.$store.getters['subject/isLoading']);
    if (!this.$store.getters['subject/isLoading']) {
      // console.log('subject load check', this.$store.getters['subject/isLoading']);
      console.debug('UserDashboard mounted setpreloader', false);
      this.$store.dispatch('setPreloader', false);
    }
  },
  // beforeUpdate() {
  //   console.log('preloader dash beforeUpdate', this.$store.getters.isPreloading);
  //   this.$store.dispatch('setPreloader', true);
  // },
  // updated() {
  //   console.log('preloader dash updated', this.$store.getters.isPreloading);
  // },
  methods: {
    fetchDecodes() {
      if (!this.$store.getters['tableUtils/hasNationList']) {
        this.$store.dispatch('tableUtils/loadNationList').then(() => {
          console.log('load nations');
        },
        () => {
          console.log('nations failed', this.$route.name);
        });
      }

      if (!this.$store.getters['tableUtils/hasAbiList']) {
        this.$store.dispatch('tableUtils/loadAbiList').then(() => {
          console.log('load abi');
        },
        () => {
          console.log('abi failed', this.$route.name);
        });
      }

      if (!this.$store.getters['tableUtils/hasMimeIcons']) {
        this.$store.dispatch('tableUtils/loadMimeIcons').then(() => {
          console.log('load mime');
        },
        () => {
          console.log('mime failed', this.$route.name);
        });
      }

      if (!this.$store.getters['documents/hasDocumentTypes']) {
        this.$store.dispatch('documents/loadDocTypes', Settings.defaultLocale).then(() => {
          console.log('load doc type');
        },
        () => {
          console.log('doc types failed', this.$route.name);
        });
      }
    },
    setDashboardState(state) {
      this.$store.dispatch('setDashboard', state);
    },
    reload() {
      console.log('qrbadge feedback reload');
      if (this.reloadData) this.reloadData();
    },
    sendTokenRequest() {
      console.log('Send validation request');
      this.$confirm(
        {
          message: 'Vuoi richiedere la verifica della tua email?',
          button: {
            no: 'No',
            yes: 'Si',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: (confirm) => {
            if (confirm) {
              this.$store.dispatch('subject/sendTokenRequest').then(() => {
                this.submitted = false;
              },
              (error) => {
                this.message = extractErrorMessage(error);
                this.submitted = false;
              });
            }
          },
        },
      );
    },
    routeTo(path) {
      this.$router.push(path);
    },
    doQuickSearch() {
      this.$bvModal.hide(this.searchModalId);
      this.$router.push({
        path: '/profile-search',
        query: {
          name: this.currentSelectionName,
          taxCode: this.currentSelectionTaxCode,
          startSearch: true,
        },
      });
    },
    resetSearch() {
      this.currentSelection = '';
      this.completed = false;
      this.hasSearchResults = false;
      this.hasError = false;
      this.hasWrongTaxCode = false;
    },
    setRequestType(val) {
      this.userChosenType = val;
      this.resetSearch();
    },
    currentTaxCodeChange(e) {
      console.log('currentTaxCodeChange');
      this.currentSelection = e.target.value;
      this.hasWrongTaxCode = !checkTaxCode(this.currentSelection);
      this.completed = false;
      this.hasSearchResults = false;
    },
    checkTaxCodeChange(e) {
      console.log('checkTaxCodeChange', e);
      this.hasWrongTaxCode = !checkTaxCode(this.currentSelection);
    },
    onSearch(search, loading) {
      console.log('searching?');
      if (search.length) {
        console.log('should be searching');
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: debounce((loading, search, vm) => {
      console.log('inside search');
      let request = null;
      request = searchCompany(search);
      if (!request) {
        // eslint-disable-next-line no-param-reassign
        vm.options = [];
        return;
      }
      request.then((res) => {
        console.log('res', res);
        // eslint-disable-next-line no-param-reassign
        vm.options = res;
        loading(false);
      }).catch((err) => {
        console.log('error search ', vm.userChosenType, err);
        loading(false);
      });
    }, 350),
    onRequestReport() {
      // console.log('report popup');
      toastInformation('Invio richiesta report');
    },
    onComplete() {
      this.$router.replace(`/mf-solvency/${this.entityType}/${this.dataTax}`);
    },
    onRegisterNew() {
      toastInformation('Invio registra nuovo');
    },
    openInfo() {
      this.$bvModal.show(this.infoModalId);
    },
    openClickReport() {
      console.log('click report search', this.currentClickTaxCode, validateVat(this.currentClickTaxCode));

      if (!this.hasFund && !this.hasPrepaid) {
        this.$confirm(
          {
            message: "Non hai abbastanza fondi per poter procedere con l'acquisto del Click Report. Vuoi ricaricare il tuo wallet?",
            button: {
              no: 'No',
              yes: 'Si',
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: (confirm) => {
              if (confirm) {
                this.$router.push('/wallet-topup');
              }
            },
          },
        );
        return;
      }
      if (this.currentClickTaxCode && validateVat(this.currentClickTaxCode)) {
        this.$bvModal.show(this.searchClickModalId);
      }
    },
    doClickReport() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      let ownerType;
      let ownerId;

      if (isNotEmpty(currentProfile)) {
        ownerType = 'company';
        ownerId = currentProfile.companyId;
      } else {
        ownerType = 'person';
        ownerId = this.$store.getters['subject/currentPerson'].personId;
      }

      const reportRequest = {
        ownerType,
        ownerId,
        taxCode: this.currentClickTaxCode,
        companyName: '',
      };

      this.$store.dispatch('subject/clickReportCompanySearch', reportRequest)
        .then((subjects) => {
          console.log('habemus search', subjects);
          this.hasError = false;
          if (subjects.length > 0) {
            const baseUrl = '/mf-solvency/company/';
            this.$router.push(`${baseUrl}${this.currentClickTaxCode}`);
          }
        },
        (error) => {
          console.error('Errore in ricerca click', error);
          this.hasError = true;
        });
    },
    openSearchModal() {
      if (!this.hasSearchFund && !this.hasSearchPrepaid) {
        this.$confirm(
          {
            message: 'Non hai abbastanza fondi per poter procedere con la ricerca. Vuoi ricaricare il tuo wallet?',
            button: {
              no: 'No',
              yes: 'Si',
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: (confirm) => {
              if (confirm) {
                this.$router.push('/wallet-topup');
              }
            },
          },
        );
        return;
      }

      this.$bvModal.show(this.searchModalId);
    },
  },
};
</script>

<style scoped>
.text-prepaid {
  color: green;
  font-weight: bold;
}
</style>
