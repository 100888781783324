<template>
  <main class="main--not-logged">
    <div class="banner">
      <img
        :src="require('../assets/img/architecture-g19c3e4465_1920_low.jpg')"
        class="banner__img"
      />
    </div>
    <div class="main-content">
      <shout-box
        :data="shoutBoxData"
      >
        <template slot="custom-content">
          <div class="shoutbox__custom-content">
            <b-card class="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 p-0">
              <div
                v-if="hasError"
                class="text-center py-5"
              >
                <div>
                  Il codice ricevuto non è più valido.<br /><br />Contattare il servizio di supporto per risolvere il problema
                </div>
                <div class="d-flex justify-content-center mt-4">
                  <router-link to="/accedi" class="btn btn-secondary btn-lg">
                    Accedi
                  </router-link>
                </div>
              </div>
              <div
                v-if="!hasError"
                class="text-center py-5"
              >
                <div v-if="!isSuccess">
                  <div>
                    Carica qui di seguito il file relativo alla richiesta
                    <strong>{{requestNameComputed}}</strong>.
                    <br />
                    Lo stesso verrà allegato alla fattura e registrato.
                  </div>
                  <div class="mt-4">
                    <b-row>
                      <b-col lg="8" offset-lg="2">
                        <vue-dropzone
                          ref="imgDropZone"
                          :id="dropZoneId"
                          :options="dropzoneOptions"
                          @vdropzone-success="afterSuccess"
                          @vdropzone-complete="afterComplete"
                        />
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div v-else>
                  <div>
                    Richiesta inviata con successo
                  </div>
                  <div class="d-flex justify-content-center mt-4">
                    <router-link to="/" class="btn btn-secondary btn-lg">
                      Home page
                    </router-link>
                  </div>
                  <div class="mt-5">
                    <p class="text-center">Non possiedi un account?
                      <b-link to="registrati">Effettua la registrazione</b-link>
                    </p>
                    <p class="m-0 text-center">Sei già registrato?
                      <b-link to="accedi">Accedi</b-link>
                    </p>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </template>
      </shout-box>
    </div>
    <footer-component />
  </main>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import { isNotEmpty } from '@/utils/validators';
import { createUriInvoiceUpload, extensionAttach, mimeTypeAttach } from '@/utils/documents';

const ShoutBox = () => import('@/components/ShoutBox.vue');
const FooterComponent = () => import('@/components/layout/FooterComponent.vue');

export default {
  name: 'UploadRequest',
  components: {
    ShoutBox,
    FooterComponent,
    vueDropzone: vue2Dropzone,
  },
  created() {
    this.checkToken();
  },
  data() {
    return {
      submitted: false,
      success: false,
      hasError: false,
      shoutBoxData: {
        title: 'Accedi o <span class="text-warning">registrati</span> per utilizzare tutti i servizi di MF Solvency',
        subtitle: 'Certifica la tua affidabilità nella gestione dei rapporti commerciali'
          + ' e nella gestione dei pagamenti, migliora il tuo scoring chiedendo i feedback'
          + ' ai tuoi fornitori',
      },
      documentToUpload: {
        document: null,
        documentName: null,
      },
    };
  },
  computed: {
    requestNameComputed() {
      if (isNotEmpty(this.$route.params.requestName)) return this.$route.params.requestName;
      return null;
    },
    dropZoneId() {
      return 'upload_dropzone';
    },
    isDoc() {
      return isNotEmpty(this.document);
    },
    dropzoneOptions() {
      return {
        url: createUriInvoiceUpload(this.requestNameComputed),
        thumbnailWidth: 350,
        thumbnailHeight: 100,
        addRemoveLinks: false,
        acceptedFiles: mimeTypeAttach.join(),
        dictDefaultMessage: `<p class='text-default'>
                                <font-awesome-icon icon="cloud-upload-alt"></font-awesome-icon>
                                 Trascina un documento o fai click in quest'area
                             </p>
          <p class="form-text">File accettati: ${extensionAttach.join()}</p>
          `,
      };
    },
    isSuccess() {
      return this.success;
    },
  },
  metaInfo() {
    return {
      title: 'Carica documento - MF Solvency',
      meta: [
        { property: 'og:title', content: 'Carica documento - MF Solvency' },
        { property: 'og:site_name', content: 'MF Solvency' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'noindex, nofollow' },
      ],
    };
  },
  methods: {
    checkToken() {
      this.hasError = false;
      this.success = false;
      this.submitted = true;
      this.$store.dispatch('invoice/verifyUploadPaymentInvoice', this.requestNameComputed)
        .then(
          (data) => {
            console.log('result', data);
            if (data) {
              this.validatedToken = data;
            } else {
              this.hasError = true;
            }

            this.submitted = false;
          },
          (error) => {
            console.log('Error check doc', error);
            this.hasError = true;
            this.submitted = false;
          },
        );
    },
    async afterSuccess(file, response) {
      console.log('response', response);
    },
    async afterComplete() {
      console.log('competed');
      this.success = true;
    },
  },
  mounted() {
    // console.debug('ValidateToken setpreloader', false);
    this.$store.dispatch('setPreloader', false);
  },
};
</script>

<style scoped>

</style>
