import Vue from 'vue';
import AnalisysService from '@/services/analisys.service';
import { toastSuccess, toastSuccessWait, toastUnableToComplete } from '@/utils/toast';
import { isNotEmpty } from '@/utils/validators';

const initialState = {
  loading: 0,
  loadingExport: 0,
  content: null,
  pageable: null,
  userAnalysis: null,
  balanceList: {
    loading: false,
    list: [],
    error: null,
  },
};

const analisys = {
  namespaced: true,
  state: initialState,
  getters: {
    isLoading: (state) => state.loading !== 0,
    isLoadingExport: (state) => state.loadingExport !== 0,
    hasUserAnalysis: (state) => isNotEmpty(state.pageable),
    userAnalysisList: (state) => state.content || [],
    userAnalysisPageable: (state) => state.pageable || {},
    getCurrentUserAnalysis: (state) => state.userAnalysis,
    hasCurrentUserAnalysis: (state) => isNotEmpty(state.userAnalysis),
    isLoadingBalance: (state) => state.balanceList.loading,
    balanceAvailableList: (state) => state.balanceList.list || [],
    hasBalanceError: (state) => isNotEmpty(state.balanceList.error),
    balanceAvailableError: (state) => state.balanceList.error,
  },
  actions: {
    createUserAnalysis({ commit }, createAnalysis) {
      commit('setLoading', true);
      return AnalisysService.createUserAnalysis(createAnalysis).then(
        (response) => {
          toastSuccess('Richiesta inviata');
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    clearUserAnalysis({ commit }) {
      commit('setUserAnalysis', null);
    },
    searchUserAnalysis({ commit }, {
      entityType, entityId, search, reset,
    }) {
      commit('setLoading', true);
      if (reset) {
        commit('clearSearch');
      }
      return AnalisysService.searchOwnUserAnalysis(entityType, entityId, search).then(
        (response) => {
          commit('searchUserAnalysisSuccess', { data: response.data });
          commit('setLoading', false);
          return Promise.resolve(response);
        },
        (error) => {
          commit('clearSearch');
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    exportUserAnalysis({ commit }, {
      entityType, entityId, search,
    }) {
      commit('setLoadingExport', true);
      return AnalisysService.exportOwnUserAnalysis(entityType, entityId, search).then(
        (response) => {
          commit('setLoadingExport', false);
          this.dispatch('addExportToken', response.data.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit('setLoadingExport', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    loadUserAnalysis({ commit }, analysisName) {
      commit('setLoading', true);
      return AnalisysService.loadUserAnalysis(analysisName).then(
        (response) => {
          commit('setUserAnalysis', response.data);
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    processMessageUserAnalysis({ commit, state }, message) {
      const { content } = state;
      if (!content) return Promise.resolve();
      const { data } = message;

      if (content.find((item) => item.requestName === data.requestName)) {
        commit('updateUserAnalysis', data);
      }
      const { labelRequestProduct, referentInfo } = data;
      const { companyName, vatNumber } = referentInfo;
      const companyMsg = companyName ? `<br>per la società ${companyName}<br>Partita Iva: ${vatNumber}<br>` : '<br>';

      toastSuccessWait(`Il documento ${labelRequestProduct} ${companyMsg} è disponibile nella sezione I miei acquisti`);
      return Promise.resolve();
    },
    cleanBalanceAvailableList({ commit }) {
      commit('clearBalanceList');
    },
    readBalanceAvailable({ commit }, searchBalance) {
      commit('setLoadingBalance', true);
      const {
        entityType,
        entityId,
        taxCode,
        productConnectType,
        productConnectCode,
      } = searchBalance;
      return AnalisysService.loadBalanceAvailableList(entityType,
        entityId,
        taxCode,
        productConnectType,
        productConnectCode).then(
        (response) => {
          console.log('response balance list', response);
          const { status, data } = response;
          let ret;
          if (status === 200) {
            ret = data.data;
          } else {
            ret = [];
          }
          commit('successBalanceList', ret);
          commit('setLoadingBalance', false);
          return Promise.resolve(ret);
        },
        (error) => {
          console.log('balance error', error);
          const { response } = error;
          if (response?.status === 400 || response?.status === 404) {
            const ret = [];
            commit('successBalanceList', ret);
            commit('setLoadingBalance', false);
            return Promise.resolve(ret);
          }

          commit('errorBalanceList', error);
          commit('setLoadingBalance', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    createClickReportPdf({ commit }, createAnalysis) {
      commit('setLoading', true);
      return AnalisysService.createClickReportPdf(createAnalysis).then(
        (response) => {
          toastSuccess('Generazione PDF avviata');
          // commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      ).finally(() => commit('setLoading', false));
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading += (loading ? 1 : -1);
    },
    setLoadingExport(state, loading) {
      state.loadingExport += (loading ? 1 : -1);
    },
    clearSearch(state) {
      state.content = null;
      state.pageable = null;
      state.userAnalysis = null;
    },
    setUserAnalysis(state, analysis) {
      state.userAnalysis = analysis;
    },
    searchUserAnalysisSuccess(state, { data }) {
      const { content, ...pageable } = data;
      state.content = content;
      state.pageable = pageable;
    },
    updateUserAnalysis(state, data) {
      const { content } = state;
      if (!content) return;
      const contentList = content.map((item) => {
        if (item.requestName === data.requestName) return data;
        return item;
      });
      state.content = contentList;
    },
    clearBalanceList(state) {
      state.balanceList = {
        loading: false,
        list: [],
        error: null,
      };
    },
    setLoadingBalance(state, loading) {
      const { balanceList } = state;
      Vue.set(state, 'balanceList', {
        ...balanceList,
        loading,
      });
    },
    successBalanceList(state, data) {
      const { balanceList } = state;
      Vue.set(state, 'balanceList', {
        ...balanceList,
        list: data,
      });
    },
    errorBalanceList(state, error) {
      const { balanceList } = state;
      Vue.set(state, 'balanceList', {
        ...balanceList,
        error,
      });
    },
  },
};

export default analisys;
