import { request } from '@/utils/request';
import { getEntityApiBase } from '@/utils/documents';
import TokenService from '@/services/token.service';

const defaultApiBase = '/backoffice-api';

const createUrl = (entityClass, entityId) => {
  const entityType = entityClass.toLowerCase();
  const response = {};
  switch (entityType) {
    case 'company':
      response.url = `/dms/company/${entityId}`;
      response.apiBase = defaultApiBase;
      break;
    case 'person':
      response.url = `/dms/person/${entityId}`;
      response.apiBase = defaultApiBase;
      break;
    default:
      response.url = `/dms/${entityType}/${entityId}`;
      response.apiBase = getEntityApiBase(entityType);
      break;
  }
  return response;
};

class DocumentService {
  updateDocument = ({
    doc,
    entityClass,
    entityId,
    note,
    status,
    sectionCode,
  }) => {
    const { documentId, documentInfo, additionalInfos } = doc;
    const newNote = note || '';
    const newStatus = status || doc.status;
    const infos = additionalInfos || {};
    const options = {
      method: 'put',
      data: {
        documentId,
        status: newStatus,
        note: newNote,
        additionalInfos: infos,
        documentInfo,
      },
    };

    const rest = createUrl(entityClass, entityId);
    const { url, apiBase } = rest;
    options.apiBase = apiBase;

    return request(`${url}/${sectionCode}`, false, options);
  };

  deleteNoteDocument = ({
    documentId,
    entityClass,
    entityId,
    noteId,
  }) => {
    const options = {
      method: 'delete',
    };

    const rest = createUrl(entityClass, entityId);
    const { url, apiBase } = rest;
    options.apiBase = apiBase;

    return request(`${url}/doc/${documentId}/note/${noteId}`, false, options);
  };

  documentTypes = (language) => request(`/dms/document-type/${language}`, TokenService.isNotLogged(), {
    method: 'get',
    apiBase: defaultApiBase,
  });

  documentWfw = () => request('/dms/document-wfw', false, {
    method: 'get',
    apiBase: defaultApiBase,
  });

  loadEntityDocuments = ({ entityClass, entityId }) => {
    const rest = createUrl(entityClass, entityId);
    const { url, apiBase } = rest;

    return request(url, TokenService.isNotLogged(), { apiBase });
  }
}

export default new DocumentService();
