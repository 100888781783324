<template>
  <user-structure
    hide-button
    hide-switch
    :title="companyNameComp"
    is-tabbed
    :has-back="!isPersonalProfile"
  >
    <template #body>
      <div v-if="isLoadingSearch">
        <b-card
          class="my-3 text-center"
        >
          <loader-info />
        </b-card>
      </div>
      <div v-else>
        <apollo-query
          :query="queryPage"
          :variables="{ taxCode }"
          fetch-policy="no-cache"
          :context="{ entityType: ownerEntityType, entityId: ownerEntityId }"
        >
          <template v-slot="{ result: { loading, error, data, fullData } }">
            <div v-if="loading" class="loading apollo">
              <b-card class="my-3 text-center">
                <loader-info />
              </b-card>
            </div>

            <!-- Error -->
            <div v-else-if="error" class="error apollo">
              <b-card class="my-3 text-center">
                <p class="my-4">
                  {{setError(fullData)}}
                  <em>
                    {{apolloErrorMsg}}
                  </em>
                </p>
                <div
                  v-if="notAllowed"
                  class="my-4"
                >
                  <p>
                    <strong>Attenzione:</strong> la visualizzazione delle informazioni dell'azienda
                    prevede un addebito pari a {{productCost}}, indipendemente dal risultato della ricerca.
                  </p>
                  <p>Premere continua per procedere con l'operazione, oppure torna alla dashboard.</p>
                </div>
                <b-card
                  class="border-0"
                  body-class="p-2 bg-gray-200 text-right"
                >
                  <div
                    v-if="notAllowed"
                    class="d-flex flex-column flex-md-row align-items-center justify-content-md-end"
                  >
                    <b-button
                      variant="link"
                      size="sm"
                      @click="routeTo('/')"
                    >
                      {{backHomeBtnLabel}}
                    </b-button>
                    <b-button
                      v-if="notAllowed"
                      class="mt-2 mt-md-0 ms-md-2"
                      variant="success"
                      @click="searchFunction"
                    >
                      Continua
                    </b-button>
                  </div>
                  <b-button
                    v-else
                    variant="warning"
                    @click="routeTo('/')"
                  >
                    {{backHomeBtnLabel}}
                  </b-button>
                </b-card>
              </b-card>
            </div>

            <!-- Result -->
            <div v-else-if="data" class="result apollo">
              {{ getData(data) }}
              <b-row>
                <b-col>
                  <div class="tab-pane__content anagr-bottom">
                    <profile-tabs-data :data="profileData" />
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <profile-tabs
                    :data="profileData"
                    :entity-type="entityType"
                    :tax-code="taxCode"
                  />
                </b-col>
              </b-row>
            </div>

            <!-- No result -->
            <div v-else class="no-result apollo">
              <div class="my-3 bg-white p-3 text-center">
                <p class="mb-3">
                  <em>
                    {{apolloNoResultsMsg}}
                  </em>
                </p>
                <b-button size="sm" @click="routeTo('/')">
                  {{backHomeBtnLabel}}
                </b-button>
              </div>
            </div>
          </template>
        </apollo-query>
      </div>
    </template>
  </user-structure>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';
import companyQuery from '@/graphql/companyQuery';
import ProfileTabsData from '@/components/profileData/ProfileTabsData.vue';

const UserStructure = () => import('@/components/layout/UserStructure.vue');
const ProfileTabs = () => import('@/components/profileData/ProfileTabs.vue');
const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');

export default {
  name: 'UserViewProfile',
  components: {
    ProfileTabsData,
    UserStructure,
    ProfileTabs,
    LoaderInfo,
  },
  data() {
    return {
      entityType: null,
      taxCode: null,
      profileData: {},
      errorsData: {},
      notFound: false,
      notAllowed: false,
      hasError: false,
    };
  },
  computed: {
    apolloErrorMsg() {
      if (this.hasError) {
        return 'Non è stato possibile recuperare le informazioni richieste. Riprova più tardi.';
      }
      if (this.notFound) {
        return `Il soggetto ${this.taxCode} non è disponibile per la visualizaazione dei dati`;
      }
      if (this.notAllowed) {
        return `Le informazioni per ${this.taxCode} sono disponibili solo per gli utenti che hanno ricercato il soggetto`;
      }
      return 'Dati correntemente in elaborazione. Riprova più tardi.';
    },
    apolloNoResultsMsg() {
      return 'Nessun dato disponibile.';
    },
    isPerson() {
      return this.entityType === 'person';
    },
    naMessage() {
      return 'N.D.';
    },
    companyNameComp() {
      return isNotEmpty(this.profileData?.info?.companyName) ? this.profileData.info.companyName : this.naMessage;
    },
    backHomeBtnLabel() {
      return 'Ritorna alla Dashboard';
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      console.log('isNotEmpty(currentProfile)', isNotEmpty(currentProfile));
      return isNotEmpty(currentProfile);
    },
    isPersonalProfile() {
      return this.$route.path === '/my-profile';
    },
    ownerEntityType() {
      return this.isCompanyProfile ? 'company' : 'person';
    },
    ownerEntityId() {
      return this.isCompanyProfile
        ? this.$store.getters['subject/currentCompany']?.companyId
        : this.$store.getters['subject/currentPerson']?.personId;
    },
    taxCodeComputed() {
      // console.log('this.$route.path', this.$route.path);
      if (this.isPersonalProfile) {
        if (this.isCompanyProfile) {
          // console.log('taxCode', this.$store.getters['subject/currentCompany'].info.taxCode);
          return this.$store.getters['subject/currentCompany'].info.taxCode;
        }
        return this.routeTo('/');
      }
      return this.$route.params.entityTax;
    },
    productCost() {
      const { price } = this.$store.getters['wallet/getPricing']('pr_company_other', 'pr_company_search');
      // console.log('price', price);
      return isNotEmpty(price) ? `${price.amount} ${price.currency}` : '';
    },
    isLoadingSearch() {
      return this.$store.getters['subject/isLoading'];
    },
  },
  created() {
    // console.log('userview', this.$route.path);
    this.entityType = this.$route.params.entityType;
    this.taxCode = this.taxCodeComputed;
    this.hasError = false;
  },
  metaInfo() {
    return {
      title: `${this.companyNameComp} - Profilo MF Solvency`,
      meta: [
        { property: 'og:title', content: `${this.companyNameComp} - Profilo MF Solvency` },
        { property: 'og:site_name', content: 'MF Solvency' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'noindex,nofollow' },
      ],
    };
  },
  methods: {
    queryPage() {
      return companyQuery;
    },
    getData(data) {
      // console.log('apollo data', data);
      // const isPerson = this.entityType === 'person';
      // this.profileData = isPerson ? data.person : data.company;
      this.profileData = data.company;
    },
    setError(fulldata) {
      // console.log('apollo data', data);
      // const isPerson = this.entityType === 'person';
      // this.profileData = isPerson ? data.person : data.company;
      this.errorsData = fulldata?.errors || [];
      if (this.errorsData.length > 0) {
        const [error] = this.errorsData;
        const { message } = error;
        this.notFound = message === '409';
        this.notAllowed = message === '403';
      }
    },
    routeTo(path) {
      this.$router.push(path);
    },
    searchFunction() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      let ownerType;
      let ownerId;

      if (isNotEmpty(currentProfile)) {
        ownerType = 'company';
        ownerId = currentProfile.companyId;
      } else {
        ownerType = 'person';
        ownerId = this.$store.getters['subject/currentPerson'].personId;
      }

      const reportRequest = {
        ownerType,
        ownerId,
        taxCode: this.taxCode,
        companyName: '',
      };

      this.$store.dispatch('subject/quickCompanySearch', reportRequest)
        .then((subjects) => {
          console.log('habemus search', subjects);
          this.hasError = false;
        },
        (error) => {
          console.error('Errore in ricerca club virtuosi', error);
          this.hasError = true;
        });
    },
  },
};
</script>

<style scoped>
.anagr-bottom {
  margin-bottom: 1.5rem !important;
}
</style>
