<template>
  <div id="app">
    <vue-confirm-dialog></vue-confirm-dialog>
    <header-empty v-if="checked && !isLogged"></header-empty>
    <header-logged v-if="checked && isLogged"></header-logged>
    <router-view v-if="checked"/>
    <preloader
      isGlobal
    />
  </div>
</template>

<script>
// import { HelmetProvider } from '@jnields/vue-helmet';
import { Settings } from 'luxon';

const HeaderLogged = () => import('@/components/layout/HeaderLogged.vue');
const HeaderEmpty = () => import('@/components/layout/HeaderEmpty.vue');
const Preloader = () => import('@/components/Preloader.vue');

export default {
  name: 'app',
  components: {
    HeaderEmpty,
    HeaderLogged,
    // HelmetProvider,
    Preloader,
  },
  mounted() {
    // console.log('current route', this.$route);
    if (this.$store.getters['auth/isLogged']) {
      this.$store.dispatch('auth/refreshToken').then(() => {
        // console.log('refresh');
        this.checked = true;
      },
      () => {
        // console.log('refresh failed', this.$route.name);
        if (this.$route.path !== '/') this.$router.replace('/');
        this.checked = true;
      });
    } else {
      this.checked = true;
    }
    this.fetchData();
    this.buildWebsocket();
  },
  data() {
    return {
      checked: false,
      isConnected: false,
      socketMessage: null,
      connection: null,
      timerId: null,
    };
  },
  computed: {
    isLogged() {
      return this.$store.getters['auth/isLogged'];
    },
  },
  watch: {
    $route: 'commonProcess',
  },
  metaInfo() {
    return {
      title: 'MF Solvency',
      meta: [
        { name: 'viewport', content: 'width=device-width, initial-scale=1, user-scalable=0' },
      ],
    };
  },
  methods: {
    buildWebsocket() {
      let urlWebsocket = `wss://${window.location.host}/notify`;
      if (process.env.NODE_ENV !== 'production') {
        if (process.env.TARGET === 'local') {
          urlWebsocket = 'ws://localhost:8888';
        } else {
          urlWebsocket = 'wss://stage.mfsolvency.it/notify';
        }
      }
      this.connection = new WebSocket(urlWebsocket);
      this.connection.onmessage = ({ data }) => {
        console.log('onmessage', data);
        // this.socketMessage = event;
        const message = JSON.parse(data);
        this.$store.dispatch('processWebsocket', message);
      };

      this.connection.onopen = () => {
        // console.log(event);
        console.log('Successfully connected to the echo websocket server...');
        this.isConnected = true;
        this.sendAuthMsg();
        if (this.timerId) {
          clearInterval(this.timerId);
        }
        this.connection.onclose = () => {
          this.isConnected = false;
          this.timerId = setInterval(() => {
            if (this.isLogged) {
              this.buildWebsocket();
            }
          }, 10000);
        };
      };
    },
    sendAuthMsg() {
      if (!this.$store.getters['auth/currentUser']) {
        console.log('delay');
        setTimeout(this.sendAuthMsg, 1000);
        return;
      }
      console.log('not delay');
      const msg = {
        type: 'authenticate',
        payload: { token: this.$store.getters['auth/currentUser'].access_token },
      };

      this.connection.send(JSON.stringify(msg));
    },
    commonProcess() {
      this.fetchData();
      if (this.isLogged && !this.isConnected) {
        this.buildWebsocket();
      } else if (!this.isLogged && this.isConnected) {
        this.connection.close();
      }
    },
    fetchData() {
      console.log('reload data');
      if (this.isLogged) {
        // if (!this.$store.getters['tableUtils/hasFeedbackType']) {
        //   this.$store.dispatch('tableUtils/loadFeedbackType', Settings.defaultLocale);
        // }
        // if (!this.$store.getters['tableUtils/hasUserReportType']) {
        //   this.$store.dispatch('tableUtils/loadUserReportType', Settings.defaultLocale);
        // }
        if (!this.$store.getters['tableUtils/hasMimeIcons']) {
          this.$store.dispatch('tableUtils/loadMimeIcons');
        }
        if (!this.$store.getters['documents/hasDocumentTypes']) {
          this.$store.dispatch('documents/loadDocTypes', Settings.defaultLocale);
        }
        if (!this.$store.getters['wallet/hasProductList']) {
          this.$store.dispatch('wallet/loadRechargeProduct');
        }
        if (!this.$store.getters['wallet/getClientId']) {
          this.$store.dispatch('wallet/loadClientId');
        }
      }
      if (!this.$store.getters['tableUtils/hasPrivacyList']) {
        this.$store.dispatch('tableUtils/loadPrivacyList', Settings.defaultLocale);
      }
      if (!this.$store.getters['tableUtils/hasTermsList']) {
        this.$store.dispatch('tableUtils/loadTermsList', Settings.defaultLocale);
      }
    },
  },
};
</script>

<style lang="scss">
#app {

}
</style>
