<template>
  <div>
    <user-structure
      hide-button
      hide-switch
      :title="headingText"
    >
      <template #body>
        <b-card class="border-0">
          <b-row>
            <b-col md="12">
              <h3>Seleziona un taglio per la ricarica</h3>
              <p>
                Seleziona un taglio per procedere con la ricarica del tuo wallet.
                <br />
                Per avere un'idea dei costi dei servizi, <a href="#" @click="openModalPricelist">clicca qui</a>.
              </p>
              <b-row class="mt-3">
                <b-col
                  lg="6"
                  v-for="walletProduct in walletProducts"
                  :key="walletProduct.productId"
                  class="mb-3"
                >
                  <wallet-button
                    :item="walletProduct"
                    :buy-product="buyProduct"
                    :price-denominator="priceDenominator"
                  />
                </b-col>
              </b-row>
            </b-col>
<!--            <b-col md="4">-->
<!--              <div class="bg-gray-200 p-3">-->
<!--                <h5>Bisogno di aiuto?</h5>-->
<!--                <p>Contattaci a <a href="#">aiuto@crp.it</a> oppure chiama l'assistenza</p>-->
<!--              </div>-->
<!--            </b-col>-->
          </b-row>
        </b-card>
      </template>
    </user-structure>
    <modal-pricelist
      id="pricelistModal"
      modalTitle="Listino prezzi"
    />
    <modal-payment id="paymentModal"
                   :product="currentProduct"
                   :subject-invoice-id="subjectInvoiceId"
                   :subject-invoice-type="subjectInvoiceType"
    />
    <modal-payment-invoice id="paymentInvoiceModal" :product="currentProduct" :complete-payment="completePayment" />
  </div>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';
import ModalPaymentInvoice from '@/components/recharge/ModalPaymentInvoice.vue';

const UserStructure = () => import('@/components/layout/UserStructure.vue');
const ModalPricelist = () => import('@/components/userSections/ModalPricelist.vue');
const WalletButton = () => import('@/components/userSections/WalletButton.vue');
const ModalPayment = () => import('@/components/recharge/ModalPayment.vue');

export default {
  name: 'UserWalletTopup',
  components: {
    ModalPaymentInvoice,
    ModalPayment,
    UserStructure,
    ModalPricelist,
    WalletButton,
  },
  data() {
    return {
      hasLoaded: false,
      currentProduct: null,
      subjectInvoiceType: null,
      subjectInvoiceId: null,
    };
  },
  props: {
    totalPoint: Number,
  },
  computed: {
    headingText() {
      return 'Wallet';
    },
    shoutBoxData() {
      return {
        title: this.headingText,
      };
    },
    walletProducts() {
      return this.$store.getters['wallet/getProductList'];
    },
    priceDenominator() {
      if (isNotEmpty(this.walletProducts)) {
        const ratio = this.walletProducts
          .filter((item) => item.creditsPurchased.amount > 0)
          .map((item) => item.creditsPurchased.amount / item.price.amount);
        return Math.min(...ratio);
      }
      return 1;
    },
  },
  metaInfo() {
    return {
      title: 'Ricarica wallet - MF Solvency',
      meta: [
        { property: 'og:title', content: 'Ricarica wallet - MF Solvency' },
        { property: 'og:site_name', content: 'MF Solvency' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'noindex,nofollow' },
      ],
    };
  },
  methods: {
    changePreloaderStatus(state) {
      this.hasLoaded = state;
      console.debug('Userwallettopup setpreloader', !state);
      this.$store.dispatch('setPreloader', !state);
    },
    // doTopUp(val) {
    //   console.log('show top-up modal/confirm', val);
    // },
    openModalPricelist() {
      this.$bvModal.show('pricelistModal');
    },
    completePayment(sbjInvoiceId, sbjInvoiceType) {
      this.subjectInvoiceType = sbjInvoiceType;
      this.subjectInvoiceId = sbjInvoiceId;
      this.$bvModal.show('paymentModal');
      this.$bvModal.hide('paymentInvoiceModal');
    },
    buyProduct(product) {
      this.currentProduct = product;
      this.subjectInvoiceType = null;
      this.subjectInvoiceId = null;
      this.$confirm(
        {
          message: "Hai bisogno di fatturare l'importo della ricarica?",
          button: {
            no: 'No',
            yes: 'Si',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: (confirm) => {
            if (confirm) {
              console.log('Fattura');
              this.$bvModal.show('paymentInvoiceModal');
            } else {
              this.$bvModal.show('paymentModal');
            }
          },
        },
      );
    },
  },
};
</script>

<style scoped>

</style>
