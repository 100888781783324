<template>
  <user-structure
    hide-button
    hide-switch
    :title="pageTitle"
  >
    <template #body>
      <profile-search-data
        v-if="isOperative"
        :search="externalRequest"
        :context="currentContext"
      />
      <div :class="historyClasses">
        <profile-search-history :context="currentContext" />
      </div>
    </template>

  </user-structure>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';

const UserStructure = () => import('@/components/layout/UserStructure.vue');
const ProfileSearchData = () => import('../components/profileSearch/ProfileSearchData.vue');
const ProfileSearchHistory = () => import('@/components/profileSearch/ProfileSearchHistory.vue');

export default {
  name: 'UserProfileSearch',
  components: { ProfileSearchHistory, ProfileSearchData, UserStructure },
  props: {
    context: String,
  },
  data() {
    return {
      entityType: null,
      taxCode: null,
      profileData: {},
    };
  },
  // mounted() {
  //   console.log('search', this.$route.query);
  //   this.$store.dispatch('subject/clearSearch');
  // },
  computed: {
    pageTitle() {
      // return this.isOperative ? 'Click Report' : null;
      return this.$props.context === 'click' ? 'Click Report' : 'Ricerca Aziende';
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
    historyClasses() {
      return this.isOperative ? 'mt-5' : 'mt-4';
    },
    externalRequest() {
      return this.$route.query;
    },
    currentContext() {
      return this.$props.context;
    },
  },
  metaInfo() {
    return {
      title: `${this.pageTitle} - MF Solvency`,
      meta: [
        { property: 'og:title', content: 'Ricerca Profilo MF Solvency' },
        { property: 'og:site_name', content: 'MF Solvency' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'noindex,nofollow' },
      ],
    };
  },
};
</script>
