<template>
  <user-structure
    hide-button
    hide-switch
    hasCreateAnalysis
    :title="this.headingText"
    has-back
  >
    <template #body>
      <user-analysis-detail-info v-if="showDetail" :user-analysis="userAnalysis" />
    </template>
  </user-structure>
</template>

<script>

import { isNotEmpty } from '@/utils/validators';

const UserStructure = () => import('@/components/layout/UserStructure.vue');
const UserAnalysisDetailInfo = () => import('@/components/userAnalysis/UserAnalysisDetailInfo.vue');

export default {
  name: 'UserAnalysisDetail',
  components: {
    UserAnalysisDetailInfo,
    UserStructure,
  },
  data() {
    return {
      hasLoaded: false,
    };
  },
  computed: {
    requestName() {
      return this.$route.params.requestName;
    },
    hasScoreData() {
      if (this.hasLoaded !== this.$store.getters['score/hasCurrentScore']) {
        this.changePreloaderStatus(this.$store.getters['score/hasCurrentScore']);
      }
      return this.$store.getters['score/hasCurrentScore'];
    },
    userAnalysis() {
      const lista = this.$store.getters['analysis/userAnalysisList'];
      const report = lista.filter((item) => item.requestName === this.requestName);
      if (report.length > 0) {
        return report[0];
      }
      return null;
    },
    showDetail() {
      return isNotEmpty(this.userAnalysis);
    },
    headingText() {
      return isNotEmpty(this.requestName) ? `Richiesta ${this.requestName}` : 'Richiesta non disponibile';
    },
    shoutBoxData() {
      return {
        title: this.headingText,
      };
    },
  },
  metaInfo() {
    return {
      title: 'Area utente - MF Solvency',
      meta: [
        { property: 'og:title', content: 'Area utente - MF Solvency' },
        { property: 'og:site_name', content: 'MF Solvency' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  methods: {
    changePreloaderStatus(state) {
      this.hasLoaded = state;
      console.debug('UserAnalysisDetail setpreloader', !state);
      this.$store.dispatch('setPreloader', !state);
    },
  },
};
</script>

<style scoped>

</style>
