import { request } from '@/utils/request';
import TokenService from '@/services/token.service';

class AuthService {
  processLoginResponse = (response) => {
    if (!response || response.status > 299) {
      console.log('processLoginResponse failed');
      return Promise.reject(response);
    }

    if (response.data.access_token) {
      TokenService.setUser(response.data);
    }

    return response.data;
  }

  login = (user) => request('/login', true, {
    method: 'post',
    processFailed: true,
    data: {
      username: user.username,
      password: user.password,
      scope: 'profile',
    },
  })
    .then((response) => this.processLoginResponse(response))

  refreshToken = () => {
    const token = TokenService.getUser();
    return request('/refresh', true, {
      method: 'post',
      data: { ...token },
    }).then((response) => this.processLoginResponse(response));
  }

  logout = () => request('/logout', true, {
    method: 'post',
    data: {
      token: TokenService.getUser(),
    },
  })

  forgotPassword = (email) => request('/forget-password', true, {
    method: 'post',
    data: {
      email,
    },
  })

  accountRegister = (data) => request('/new-account-request', true, {
    method: 'post',
    data,
    apiBase: '/backoffice-api',
  })

  register = (user) => {
    const {
      firstName, lastName, email, password, confirmPassword,
    } = user;

    return request('/create-user', true, {
      method: 'post',
      data: {
        firstName,
        lastName,
        username: email,
        email,
        password,
        confirmPassword,
        autologin: true,
      },
    }).then((response) => {
      if (response.status === 409) {
        return response;
      }
      if (response.data.access_token) {
        TokenService.setUser(response.data);
        // localStorage.setItem(keyStorage, JSON.stringify(response.data));
      }

      return response.data;
    });
  }
}

export default new AuthService();
