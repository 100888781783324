<template>
  <user-structure
    hide-button
    hide-switch
    :title="shoutBoxData"
    has-back
  >
    <template #body>
      <b-card>
        <div
          v-if="isLoading"
          class="py-3"
        >
          <loader-info />
        </div>
        <div v-if="!isLoading">
          <div v-if="!showDetail">
            Richiesta {{requestName}} non trovata
          </div>
          <user-invoice-detail-info
            v-if="showDetail"
            :user-invoice="userInvoice"
            :requestName="requestName"
          />
        </div>
      </b-card>
    </template>
  </user-structure>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';
import LoaderInfo from '@/components/helpers/LoaderInfo.vue';

const UserStructure = () => import('@/components/layout/UserStructure.vue');
const UserInvoiceDetailInfo = () => import('@/components/userInvoice/UserInvoiceDetailInfo.vue');

export default {
  name: 'UserInvoiceDetail',
  components: {
    UserInvoiceDetailInfo,
    UserStructure,
    LoaderInfo,
  },
  data() {
    return {
      hasLoaded: false,
    };
  },
  computed: {
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    isLoading() {
      return this.$store.getters['invoice/isLoading'];
    },
    requestName() {
      return this.$route.params.requestName;
    },
    hasScoreData() {
      if (this.hasLoaded !== this.$store.getters['score/hasCurrentScore']) {
        this.changePreloaderStatus(this.$store.getters['score/hasCurrentScore']);
      }
      return this.$store.getters['score/hasCurrentScore'];
    },
    userInvoice() {
      return this.$store.getters['invoice/getCurrentInvoice'];
    },
    showDetail() {
      return isNotEmpty(this.userInvoice);
    },
    shoutBoxData() {
      if (this.showDetail) {
        return `Dettaglio fattura ${this.userInvoice.invoiceInfo.invoiceNumber}`;
      }
      return 'Dettaglio fattura';
    },
  },
  metaInfo() {
    return {
      title: `${this.shoutBoxData} - MF Solvency`,
      meta: [
        { property: 'og:title', content: `${this.shoutBoxData} - MF Solvency` },
        { property: 'og:site_name', content: 'MF Solvency' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'noindex,nofollow' },
      ],
    };
  },
  methods: {
    changePreloaderStatus(state) {
      this.hasLoaded = state;
      console.debug('UserInvoiceDetail setpreloader', !state);
      this.$store.dispatch('setPreloader', !state);
    },
  },
  mounted() {
    if (!this.isCompanyProfile) {
      this.$router.replace('/dashboard-utente');
    }
    this.$store.dispatch('invoice/getInvoice', this.requestName);
  },
};
</script>

<style scoped>

</style>
