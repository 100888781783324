<template>
  <div>
    <user-structure
      hide-button
      hide-switch
      :title="headingText"
    >
      <template #body>
        <b-card class="border-0">
          <b-row>
            <b-col md="4" class="d-flex mt-3 mt-md-0 flex-column">
              <b-card footer-class="p-0 overflow-hidden">
                <template #header>
                  <card-header
                    title="Credito disponibile"
                  />
                </template>
                <div class="text-center py-4">
                  <h1 :class="totalPointHighlightClasses">
                    {{computedCredit}}
                    <span>
                      {{walletCurrency}}
                    </span>
                  </h1>
                </div>
              </b-card>
<!--              <b-card footer-class="p-0 overflow-hidden" v-if="hasPrepaid">-->
<!--                <template #header>-->
<!--                  <card-header-->
<!--                    title="Documenti pre acquistati"-->
<!--                  />-->
<!--                </template>-->
<!--                <div class="text-center py-4">-->
<!--                  <b-row v-for="item in prepaidList" :key="item.code">-->
<!--                    <b-col md="9"><h6>{{item.name}}</h6></b-col>-->
<!--                    <b-col md="3"><h6>{{item.amount}}</h6></b-col>-->
<!--                  </b-row>-->
<!--                </div>-->
<!--              </b-card>-->
              <div class="mt-4" v-if="walletProducts.length > 0">
                <div
                  v-for="walletProduct in walletProducts"
                  :key="walletProduct.productId"
                  class="mb-3"
                >
                  <wallet-button
                    :item="walletProduct"
                    :buy-product="buyProduct"
                    :price-denominator="priceDenominator"
                  />
                </div>
              </div>
              <div
                v-if="isOperative"
                class="mt-3"
              >
                <b-button
                  size="lg"
                  variant="info"
                  block
                  @click="routeTo('wallet-topup')"
                >
                  Ricarica
                </b-button>
              </div>
            </b-col>
            <b-col md="8" class="mt-3 mt-md-0">
              <b-card>
                <template #header>
                  <card-header
                    title="Transazioni"
                  />
                </template>

                <wallet-transaction-table
                  :is-loading="isLoadingTransactions"
                  :entity-type="entityType"
                  :entity-id="entityId"
                />
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </template>
    </user-structure>
    <modal-payment id="paymentModal"
                   :product="currentProduct"
                   :subject-invoice-id="subjectInvoiceId"
                   :subject-invoice-type="subjectInvoiceType"
    />
    <modal-payment-invoice id="paymentInvoiceModal" :product="currentProduct" :complete-payment="completePayment" />
  </div>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';
import { formatDecimalNumber } from '@/utils/formatterHelper';
import ModalPaymentInvoice from '@/components/recharge/ModalPaymentInvoice.vue';

const UserStructure = () => import('@/components/layout/UserStructure.vue');
const WalletTransactionTable = () => import('@/components/userWallet/WalletTransactionTable.vue');
const WalletButton = () => import('@/components/userSections/WalletButton.vue');
const ModalPayment = () => import('@/components/recharge/ModalPayment.vue');
const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');

export default {
  name: 'UserWallet',
  components: {
    ModalPaymentInvoice,
    UserStructure,
    WalletTransactionTable,
    WalletButton,
    ModalPayment,
    CardHeader,
  },
  data() {
    return {
      hasLoaded: false,
      currentProduct: null,
      subjectInvoiceType: null,
      subjectInvoiceId: null,
    };
  },
  props: {
    totalPoint: Number,
  },
  created() {
    if (!this.$store.getters['tableUtils/hasProductType'] && !this.$store.getters['tableUtils/isLoadingProductType']) {
      this.$store.dispatch('tableUtils/loadProductType', 'it').then(() => {
        console.log('loaded products');
      });
    }
  },
  computed: {
    headingText() {
      return 'Wallet';
    },
    shoutBoxData() {
      return {
        title: this.headingText,
      };
    },
    computedCredit() {
      if (isNotEmpty(this.$store.getters['wallet/getWallet']?.amount)) {
        const a = this.$store.getters['wallet/getWallet'].amount.amount;
        return formatDecimalNumber(a, 2, 'it');
      }
      return 0;
    },
    totalPointHighlightClasses() {
      const baseClasses = 'm-auto point-highlight point-highlight--wallet';
      if (isNotEmpty(this.$store.getters['wallet/getWallet']?.amount)) {
        const a = this.$store.getters['wallet/getWallet'].amount.amount.toString().length;
        if (a >= 3 && a < 5) {
          return `${baseClasses} point-highlight--100`;
        }
        if (a >= 5 && a < 8) {
          return `${baseClasses} point-highlight--1000`;
        }
        if (a >= 8) {
          return `${baseClasses} point-highlight--10000`;
        }
      }
      return baseClasses;
    },
    walletCurrency() {
      return this.$store.getters['wallet/getWallet']?.amount.currency || '';
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
    entityType() {
      return this.isCompanyProfile ? 'company' : 'person';
    },
    entityId() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      if (isNotEmpty(currentProfile)) {
        return currentProfile.companyId;
      }
      return this.$store.getters['subject/currentPerson']?.personId;
    },
    isLoadingTransactions() {
      return this.$store.getters['wallet/isLoading'];
    },
    walletProducts() {
      return this.$store.getters['wallet/getProductList'].filter((item) => item.highlight);
    },
    priceDenominator() {
      if (isNotEmpty(this.walletProducts)) {
        const ratio = this.walletProducts
          .filter((item) => item.creditsPurchased.amount > 0)
          .map((item) => item.creditsPurchased.amount / item.price.amount);
        return Math.min(...ratio);
      }
      return 1;
    },
    prepaidList() {
      const wallet = this.$store.getters['wallet/getWallet'];
      if (!wallet) return [];
      const { productsTypePrePaid } = wallet;
      if (!productsTypePrePaid) return [];
      const prepaids = [];

      const productTypes = Object.keys(productsTypePrePaid);
      productTypes.forEach((product) => {
        const { productsCodePrePaid } = productsTypePrePaid[product];
        if (productsCodePrePaid) {
          const productCodes = Object.keys(productsCodePrePaid);
          const lista = this.$store.getters['tableUtils/getTableList'](product) || [];
          productCodes.forEach((code) => {
            const prod = lista.find((item) => item.key === code);
            if (prod) {
              if (productsCodePrePaid[code] > 0) {
                prepaids.push({
                  code: `${product}_${code}`,
                  name: prod.value,
                  amount: productsCodePrePaid[code],
                });
              }
            }
          });
        }
      });

      return prepaids;
    },
    hasPrepaid() {
      return this.prepaidList.length > 0;
    },
  },
  metaInfo() {
    return {
      title: 'Area utente - MF Solvency',
      meta: [
        { property: 'og:title', content: 'Area utente - MF Solvency' },
        { property: 'og:site_name', content: 'MF Solvency' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'noindex,nofollow' },
      ],
    };
  },
  methods: {
    changePreloaderStatus(state) {
      this.hasLoaded = state;
      console.debug('UserWallet setpreloader', !state);
      this.$store.dispatch('setPreloader', !state);
    },
    routeTo(path) {
      this.$router.replace(path);
    },
    completePayment(sbjInvoiceId, sbjInvoiceType) {
      this.subjectInvoiceType = sbjInvoiceType;
      this.subjectInvoiceId = sbjInvoiceId;
      this.$bvModal.show('paymentModal');
      this.$bvModal.hide('paymentInvoiceModal');
    },
    buyProduct(product) {
      this.currentProduct = product;
      this.subjectInvoiceType = null;
      this.subjectInvoiceId = null;
      this.$confirm(
        {
          message: "Hai bisogno di fatturare l'importo della ricarica?",
          button: {
            no: 'No',
            yes: 'Si',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: (confirm) => {
            if (confirm) {
              console.log('Fattura');
              this.$bvModal.show('paymentInvoiceModal');
            } else {
              this.$bvModal.show('paymentModal');
            }
          },
        },
      );
    },
  },
};
</script>

<style scoped>

</style>
