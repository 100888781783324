<template>
  <modal-wrapper
    :id="id"
    size="lg"
    :on-modal-show="loadCompaniesList"
    :on-modal-hidden="doReset"
    :no-auto-close="disableClose"
  >
    <template #title>
      {{modalTitle}}
    </template>
    <template #body>
      <div>
        <p>
         Scegli a chi voi intestare la fattura di acquisto ricarica
        </p>
        <b-row cols-md="2">
          <b-col>
            <b-checkbox
              v-model="askInvoicePerson"
              @change="(value) => setInvoiceRequest(value, false)"
            >A me</b-checkbox>
          </b-col>
          <b-col>
            <b-checkbox
              v-model="askInvoiceCompany"
              @change="(value) => setInvoiceRequest(value, true)"
            >Società</b-checkbox>
          </b-col>
        </b-row>
        <b-row cols-md="12">
          <div v-if="askInvoicePerson">
            <form-invoice-person @invoice-person-data="getInvoiceData"/>
          </div>
          <div v-if="askInvoiceCompany">
            <form-invoice-company @invoice-company-data="getInvoiceData" :companies-invoice="companiesInvoice"/>
          </div>
        </b-row>
      </div>
    </template>
    <template #footer>
      <b-row cols-md="12">
        <b-col>
          <b-button :disabled="invalidSubjectData" @click="buyProduct">
            Acquista Ricarica
          </b-button>
        </b-col>
      </b-row>
    </template>
  </modal-wrapper>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';

const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');
const FormInvoicePerson = () => import('@/components/forms/FormInvoicePerson.vue');
const FormInvoiceCompany = () => import('@/components/forms/FormInvoiceCompany.vue');

export default {
  name: 'ModalPaymentInvoice',
  components: {
    ModalWrapper,
    FormInvoiceCompany,
    FormInvoicePerson,
  },
  props: {
    id: String,
    product: {
      productId: String,
      description: String,
      price: {
        currency: String,
        amount: Number,
      },
      creditsPurchased: {
        currency: String,
        amount: Number,
      },
    },
    completePayment: Function,
  },
  data() {
    return {
      askInvoicePerson: false,
      askInvoiceCompany: false,
      invoiceSubjectData: null,
      invalidSubjectData: false,
    };
  },
  computed: {
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    disableClose() {
      return this.inProgress && !this.failed;
    },
    entityType() {
      return this.isCompanyProfile ? 'company' : 'person';
    },
    entityId() {
      return this.isCompanyProfile
        ? this.$store.getters['subject/currentCompany'].companyId
        : this.$store.getters['subject/currentPerson'].personId;
    },
    modalTitle() {
      let titleVar = 'Acquisto Ricarica - Dati Fatturazione';
      if (isNotEmpty(this.product.description)) titleVar = `Acquisto ${this.product.description}`;
      return titleVar;
    },
  },

  methods: {
    loadCompaniesList() {
      try {
        this.$store.dispatch('subject/loadCompaniesInvoice')
          .then((companies) => {
            console.debug('companies', companies);
            this.companiesInvoice = companies || [];
          }).catch((error) => console.error('fetch company', error));
      } catch (error) {
        console.error('loadCompaniesList error', error);
      }
    },
    doReset() {
      console.log('trying reset');
      this.askInvoicePerson = false;
      this.askInvoiceCompany = false;
      this.invoiceSubjectData = null;
      this.invalidSubjectData = false;
      this.companiesInvoice = [];
    },
    closeModal() {
      console.log('closing ', this.id);
      this.$bvModal.hide(this.id);
    },
    setInvoiceRequest(value, isCompany) {
      console.log('setInvoiceRequest', value);
      if (isCompany) {
        this.askInvoicePerson = false;
      } else {
        this.askInvoiceCompany = false;
      }
      if (!this.askInvoicePerson && !this.askInvoiceCompany) {
        this.invalidSubjectData = false;
      }
    },
    getInvoiceData(event) {
      console.log('getInvoiceData', event);
      const { invoiceData, invalidForm } = event;
      this.invoiceSubjectData = invoiceData;
      this.invalidSubjectData = invalidForm;
    },
    buyProduct() {
      if (this.askInvoicePerson || this.askInvoiceCompany) {
        if (this.invalidSubjectData) return;

        let saveData;
        if (this.askInvoicePerson) {
          saveData = this.$store.dispatch('subject/saveSubject', {
            person: this.invoiceSubjectData,
            disable: true,
          });
        } else if (this.askInvoiceCompany) {
          if (!this.invoiceSubjectData.info?.vatNumber) {
            if (!this.invoiceSubjectData.info) {
              this.invoiceSubjectData.info = {};
            }
            this.invoiceSubjectData.info.vatNumber = this.invoiceSubjectData.invoiceReference.vatNumber;
          }
          if (!this.invoiceSubjectData.info?.taxCode) {
            if (!this.invoiceSubjectData.info) {
              this.invoiceSubjectData.info = {};
            }
            this.invoiceSubjectData.info.taxCode = this.invoiceSubjectData.invoiceReference.taxCode;
          }
          saveData = this.$store.dispatch('subject/saveSubject', {
            company: this.invoiceSubjectData,
            setInvoiceTo: true,
            disable: true,
          });
        } else {
          console.log('buyProduct - to implement');
          return;
        }

        saveData.then((response) => {
          let subjectInvoiceType;
          let subjectInvoiceId;
          if (this.askInvoicePerson) {
            subjectInvoiceType = 'person';
            subjectInvoiceId = response.data.data.person.personId;
          } else if (this.askInvoiceCompany) {
            subjectInvoiceType = 'company';
            subjectInvoiceId = response.data.data.company.companyId;
          } else {
            return;
          }
          if (this.$props.completePayment) {
            this.$props.completePayment(subjectInvoiceId, subjectInvoiceType);
          }
        });
      }
    },
  },
};
</script>

<style scoped>
#paypal-button-container {
  margin: 30px 0;
}
</style>
