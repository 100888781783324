<template>
  <user-structure
    hide-button
    hide-switch
    :title="currentName"
    is-tabbed
  >
    <template #body>
      <div>
        <tab-data />
      </div>
    </template>
  </user-structure>
</template>

<script>
import { extractErrorMessage, isNotEmpty } from '@/utils/validators';
import { normalizeDisplayName } from '@/utils/formatterHelper';

const UserStructure = () => import('@/components/layout/UserStructure.vue');
const TabData = () => import('@/components/userSections/TabData.vue');

export default {
  name: 'UserData',
  components: {
    UserStructure,
    TabData,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      userPlaceholder: require('@/assets/img/user-img_placeholder.png'),
      // eslint-disable-next-line global-require
      iconFinancial: require('@/assets/img/financial.svg'),
      // eslint-disable-next-line global-require
      iconMarket: require('@/assets/img/market-analysis.svg'),
      // eslint-disable-next-line global-require
      iconOffice: require('@/assets/img/office-building.svg'),
      // eslint-disable-next-line global-require
      iconQuality: require('@/assets/img/quality-control.svg'),
      // eslint-disable-next-line global-require
      iconReview: require('@/assets/img/review.svg'),
      // eslint-disable-next-line global-require
      iconReview2: require('@/assets/img/review_2.svg'),
      // eslint-disable-next-line global-require
      iconSettings: require('@/assets/img/settings.svg'),
      hasLoaded: null,
    };
  },
  computed: {
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    hasScoreData() {
      if (this.hasLoaded !== this.$store.getters['score/hasCurrentScore']) {
        this.changePreloaderStatus(this.$store.getters['score/hasCurrentScore']);
      }
      return this.$store.getters['score/hasCurrentScore'];
    },
    serialCode() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.serialCode;
      }
      return '';
    },
    entityEmail() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/currentCompany'].attribute.mainPecEmail.contactData;
      }
      return this.$store.getters['subject/currentPerson']?.personInfo.email;
    },
    currentName() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/currentCompany'].info.companyName;
      }
      return normalizeDisplayName(this.$store.getters['auth/currentName']);
    },
    userName() {
      return normalizeDisplayName(this.$store.getters['auth/currentName']);
    },
    userEmail() {
      return this.$store.getters['auth/currentEmail'];
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        console.log('logging company', this.$store.getters['subject/activityCompanyStatus']);
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
    totalPoint() {
      if (this.activityStatus.operative === false) return 'N.D.';
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.totalPoint;
      }
      return 0;
    },
    receivedClasses() {
      if ((this.countTotal / this.countMax) * 100 < 10) {
        return 'progress--lg progress--value-xs';
      }
      return 'progress--lg';
    },
    countTotal() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.globalVotes?.countTotal;
      }
      return 0;
    },
    countMax() {
      let increm = this.countTotal * 0.2;
      if (increm < 1) {
        increm = 2;
      }
      return this.countTotal + increm;
    },
    viewState() {
      return this.$store.getters.getDashboardState;
    },
    extendedStatus() {
      return this.$store.getters['subject/currentExtendedStatus'];
    },
    isActiveUser() {
      return this.$store.getters['subject/isActiveUser'];
    },
    dashcardBodyEvalClasses() {
      if (this.isOperative) return 'dashcard__body my-auto';
      return 'dashcard__body';
    },
  },
  metaInfo() {
    return {
      title: 'Area utente - MF Solvency',
      meta: [
        { property: 'og:title', content: 'Area utente - MF Solvency' },
        { property: 'og:site_name', content: 'MF Solvency' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  methods: {
    openModalFeedback() {
      this.$bvModal.show('userFeedback');
    },
    addCompanyModal() {
      this.$bvModal.show('addCompany');
    },
    changePreloaderStatus(state) {
      this.hasLoaded = state;
      // console.debug('UserData setpreloader', !state);
      this.$store.dispatch('setPreloader', !state);
    },
    setDashboardState(state) {
      this.$store.dispatch('setDashboard', state);
    },
    reload() {
      console.log('qrbadge feedback reload');
      if (this.reloadData) this.reloadData();
    },
    sendTokenRequest() {
      console.log('Send validation request');
      this.$confirm(
        {
          message: 'Vuoi richiedere la verifica della tua email?',
          button: {
            no: 'No',
            yes: 'Si',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: (confirm) => {
            if (confirm) {
              this.$store.dispatch('subject/sendTokenRequest').then(() => {
                this.submitted = false;
              },
              (error) => {
                this.message = extractErrorMessage(error);
                this.submitted = false;
              });
            }
          },
        },
      );
    },
    routeTo(path) {
      this.$router.replace(path);
    },
  },
};
</script>
